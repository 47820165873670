import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import orderDefined from '../OrderDefined';

import * as filterTypes from '../../../redux/futures/filter/types';
import filterActions from '../../../redux/futures/filter/actions';

import * as ticketSeatTypes from '../../../redux/futures/ticket_seat/types';
import ticketSeatActions from '../../../redux/futures/ticket_seat/actions';

import * as orderTypes from '../../../redux/futures/order/types';
import orderActions from '../../../redux/futures/order/actions';

import printActions from '../../../redux/futures/print/actions';
import productVariantAction from '../../../redux/futures/product_variant/actions';

import * as shippingTypes from '../../../redux/futures/shipping/types';
import shippingActions from '../../../redux/futures/shipping/actions';

import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import PopoverDiscount from './components/PopoverDiscount';
import PopoverDiscountDetail from './components/PopoverDiscountDetail';
import ConfirmModal from '../../../components/ConfirmModal';
import ProductSearch from './components/ProductSearch';
import ModalTicketSeat from './components/ModalTicketSeat';
import ModalTicketSeatEditCustomer from './components/ModalTicketSeatEditCustomer';
import config from '../../../config/config';
import productHelper from '../../../helpers/ProductHelper';
// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
export const OrderProducts = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const { setVariantSelected, variantSelected, isEditableOrder, orderData, setShowModalShipping } = props;

    const storeReducer = useSelector((state) => state.store);
    const orderReducer = useSelector((state) => state.order);
    const variantReducer = useSelector((state) => state.variant);
    const shippingReducer = useSelector((state) => state.shipping);
    const ticketSeatReducer = useSelector((state) => state.ticketSeatReducer);

    const loading = useMemo(() => {
        return variantReducer.loading || false;
    }, [variantReducer.loading]);

    const isLoadingShipping = useMemo(() => {
        return shippingReducer.loading || false;
    }, [shippingReducer.loading]);

    const isLoadingConfirmResendTicket = useMemo(() => {
        return ticketSeatReducer.loading || false;
    }, [ticketSeatReducer.loading]);

    const [products, setProducts] = useState([]);
    const [productSelected, setProductSelected] = useState([]);
    const [shippingInfo, setShippingInfo] = useState({});
    const [shippingInfos, setShippingInfos] = useState([]);
    const [ticketCustomerSelected, setTicketCustomerSelected] = useState({});

    const [isShowModalTicket, setShowModalTicket] = useState(false);
    const [isShowModalConfirmResendTicket, setShowModalConfirmResendTicket] = useState(false);
    const [isShowModalTicketEditCustomer, setShowModalTicketEditCustomer] = useState(false);

    useEffect(() => {
        // dispatch(productVariantAction.search({ search: '' }));

        if (shippingReducer.action == shippingTypes.SHIPPING_UPDATE_STATUS) {
            if (shippingReducer.status) {
                window.LadiUI.closeModal('confirm-shipping-cancel');
                dispatch(orderActions.reload(orderData.order_id));
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), shippingReducer.message, 'OK');
            }
        }

        if (shippingReducer.action == shippingTypes.UPDATE_SHIPPING) {
            if (shippingReducer.status) {
                dispatch(orderActions.reload(orderData.order_id));
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), shippingReducer.message, 'OK');
            }
        }
    }, [shippingReducer.action]);

    useEffect(() => {
        // dispatch(productVariantAction.search({ search: '' }));

        if (orderReducer.action == orderTypes.RESEND_TICKET_LIST) {
            if (orderReducer.status) {
                window.LadiUI.toastCustom('success', '', orderReducer.message);
                setShowModalConfirmResendTicket(false);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderReducer.message, 'OK');
            }
        }
    }, [orderReducer.action]);

    useEffect(() => {
        if (orderData.shippings && orderData.shippings.length > 0) {
            setShippingInfo(orderData.shippings[0]);
            setShippingInfos(orderData.shippings || []);
        }
    }, [orderData]);

    const selectVariant = (variant, quantity = 1) => {
        if (!baseHelper.isAvailableVariant(variant)) {
            return;
        }

        const variants = [...variantSelected];
        const {
            product_id: productID,
            product_variant_id: variantID,
            name,
            price,
            src,
            weight,
            options,
            max_buy,
            min_buy,
            package_quantity_unit,
        } = variant;

        // let _variant = variants.find(
        //     (item) => item.product_id && item.variant_id && item.product_id == productID && item.variant_id == variantID
        // );
        let index = variants.findIndex(
            (item) => item.product_id && item.variant_id && item.product_id == productID && item.variant_id == variantID
        );

        if (index > -1) {
            variants[index].quantity += 1;
            setVariantSelected(variants);
            return;
        }

        const newVariant = {
            product_id: productID,
            variant_id: variantID,
            product_variant_id: variantID,
            name,
            price,
            weight,
            src,
            quantity: quantity || 1,
            total: price * (quantity || 1),
            options,
            max_buy,
            min_buy,
            package_quantity_unit,
        };

        variants.push(newVariant);
        setVariantSelected(variants);
    };

    const changeQuantityVariant = (value, item, index, isRemovve = false) => {
        const max_buy = item.max_buy;
        const mim_buy = item.min_buy || 1;

        if (value < mim_buy && !isRemovve) {
            window.LadiUI.toastCustom('danger', '', t('STORES.MSG_MIN_BUY'));
            return;
        }
        const variants = [...variantSelected];

        const message = t('STORES.MSG_MAX_BUY');
        const selectVariant = variants[index];

        if (max_buy != null && value > max_buy) {
            window.LadiUI.toastCustom('danger', '', message);
            return;
        }

        if (value <= 0) {
            variants.splice(index, 1);
        }

        if (!selectVariant.quantity) {
            selectVariant.quantity = 1;
        }

        selectVariant.quantity = Number(value);

        setVariantSelected(variants);
    };

    const applyVariantDiscount = (index, discount) => {
        const variants = [...variantSelected];
        variants[index].discount = discount;
        variants[index].is_custom_discount = true;
        setVariantSelected(variants);
    };
    const removeVariantDiscount = (index, discount) => {
        const variants = [...variantSelected];
        variants[index].discount = {};
        variants[index].is_custom_discount = false;
        setVariantSelected(variants);
    };

    const getLastPrice = (variant) => {
        if (!variant.discount) {
            return baseHelper.parseFloat(variant.price);
        }
        const discountFee = baseHelper.calculateDiscountFee(
            variant.price,
            variant.discount.type,
            variant.discount.value,
            variant.discount.maximum_discount
        );
        return baseHelper.parseFloat(variant.price) - baseHelper.parseFloat(discountFee);
    };

    const confirmResendTicket = () => {
        let data = {
            order_id: orderData.order_id,
        };
        dispatch(orderActions.resendTicketList(data));
        // this.props.resendTicketList(data);
    };

    return (
        <>
            <div className='order-products ladiui container-place'>
                {shippingInfos && shippingInfos.length > 0 && (
                    <div>
                        <div className='ladiui flex-center-between'>
                            <div className='order-product-title'>
                                <label className='ladiui label-18'>{t('SHIPPINGS.TITLE')}</label>
                            </div>
                            {!isEditableOrder && (
                                <div
                                    className={`ladiui tag mb-12 shipping-${orderData.shipping_status
                                        ? orderData.shipping_status.toLowerCase()
                                        : appConfig.SHIPPING_STATUS.PENDING.CODE.toLowerCase()
                                        }`}
                                >
                                    {orderDefined.getShippingStatus(orderData.shipping_status)}
                                </div>
                            )}
                        </div>
                        <div className='mt-20'>
                            <table className='ladiui table text-left table-order main-table'>
                                <thead>
                                    <tr className='ladiui table-vertical'>
                                        <th scope='col'>{t('SHIPPINGS.TITLE')}</th>

                                        <th scope='col' className='ladiui text-center'>
                                            {t('SHIPPINGS.SHIPPING_PARTNER')}
                                        </th>

                                        <th scope='col' className='ladiui text-center'>
                                            {t('SHIPPINGS.SHIPPING_CODE')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shippingInfos.map((item, index) => {
                                        return (
                                            <tr className={`ladiui table-vertical main`} key={index}>
                                                <td
                                                    className={`${item.status == appConfig.SHIPPING_STATUS.CANCELED.CODE ? 'text-line-through' : ''
                                                        }`}
                                                >
                                                    {/* <a href={`/shippings/${item.shipping_id}`}>{item.shipping_id}</a> */}
                                                    <a
                                                        href={`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.SHIPPING_UPDATE(
                                                            item.shipping_id
                                                        )}`}
                                                        target='_blank'
                                                    >
                                                        {item.shipping_id}
                                                    </a>
                                                </td>
                                                <td
                                                    className={`ladiui text-center ${item.status == appConfig.SHIPPING_STATUS.CANCELED.CODE ? 'text-line-through' : ''
                                                        }`}
                                                >
                                                    {item.shipping_partner_name}
                                                </td>
                                                <td
                                                    className={`ladiui text-center ${item.status == appConfig.SHIPPING_STATUS.CANCELED.CODE ? 'text-line-through' : ''
                                                        }`}
                                                >
                                                    {baseHelper.getTrackingHref(item)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                <div className='ladiui flex-center-between pb-12'>
                    <div className='order-product-title'>
                        <label className='ladiui label-18'>{t('PRODUCTS.PRODUCT_INFO')}</label>
                    </div>
                    {!isEditableOrder && orderData.order_id && (!shippingInfos || shippingInfos.length == 0) && (
                        <div
                            className={`ladiui tag mb-12 shipping-${orderData.shipping_status
                                ? orderData.shipping_status.toLowerCase()
                                : appConfig.SHIPPING_STATUS.PENDING.CODE.toLowerCase()
                                }`}
                        >
                            {orderDefined.getShippingStatus(orderData.shipping_status)}
                        </div>
                    )}
                </div>
                {isEditableOrder && <ProductSearch selectVariant={selectVariant} />}

                {variantSelected.length == 0 && (
                    <div className='product-empty'>
                        <i className='ladiui icon-new new-ldicon-box' />
                        <div className='mt-8 ladiui text-14'> {t('PRODUCTS.PRODUCT_EMPTY')}</div>
                    </div>
                )}
                {variantSelected.length > 0 && (
                    <div className='variant-selected-list'>
                        <div className='variant-selected-item display-flex-center'>
                            <div className='variant-image-name-option ladiui heading-label-2'>{t('MENU.PRODUCTS')}</div>
                            <div className='variant-quatity ladiui heading-label-2'> {t('COMMON.QUANTITY')} </div>
                            <div className='variant-price-discount ladiui heading-label-2'> {t('COMMON.PRICE')} </div>
                            <div className='variant-total ladiui heading-label-2'> {t('COMMON.TOTAL_AMOUNT')} </div>
                            {isEditableOrder && <div className='variant-delete'> </div>}
                        </div>
                        {variantSelected.map((item, index) => {
                            let imageSrc = baseHelper.getImageUrl(item.src);

                            // let optionNames = item.options ? item.options.map((option) => option.option_value_value) : [];
                            // let optionName = optionNames.join(', ');
                            // if (item.option_name) optionName = item.option_name;

                            let optionName = productHelper.getOptionUpsellNew(item, item.product_type);
                            return (
                                <div className='variant-selected-item display-flex-center boder-top' key={index}>
                                    <div className='variant-image-name-option display-flex-center'>
                                        <img className='variant-image mr-24' src={imageSrc} alt='variant-selected-image' />
                                        <div className='variant-name-option'>
                                            <a
                                                className='variant-name ladiui label-16'
                                                href={baseHelper.getUrlProduct(item.product_type, item.product_id)}
                                                target='_blank'
                                            >
                                                {item.name}
                                            </a>
                                            <div className='option ladiuid text-14 mt-4'>{optionName}</div>
                                            {orderData &&
                                                orderData.order_id &&
                                                orderData.payment_status == appConfig.PAYMENT_STATUS.SUCCESS.CODE &&
                                                item.ticket_creation_form > 0 &&
                                                item.product_type == appConfig.PRODUCT_TYPE.EVENT.CODE && (
                                                    <div
                                                        className='display-flex-center mt-4 pointer'
                                                        onClick={() => {
                                                            setProductSelected(item);
                                                            setShowModalTicket(true);
                                                        }}
                                                    >
                                                        <i className='new-ldicon-ticket mr-8' />
                                                        <span>{t('PRODUCT_EVENTS.TAB_EVENT_SEATS')}</span>
                                                    </div>
                                                )}

                                            {orderData &&
                                                orderData.order_id &&
                                                orderData.payment_status == appConfig.PAYMENT_STATUS.SUCCESS.CODE &&
                                                item.ticket_creation_form > 0 &&
                                                item.product_type == appConfig.PRODUCT_TYPE.SERVICE.CODE && (
                                                    <div
                                                        className='display-flex-center mt-4 pointer'
                                                        onClick={() => {
                                                            setProductSelected(item);
                                                            setShowModalTicket(true);
                                                        }}
                                                    >
                                                        <i className='new-ldicon-bill  mr-8' />
                                                        <span>{t('TICKET_SEAT.LABEL_SERVICE')}</span>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    <div className='variant-quatity'>
                                        {isEditableOrder ? (
                                            <div className='variant-quantity-up-down'>
                                                {/* <span>{item.quantity}</span> */}

                                                <>
                                                    <input
                                                        type='number'
                                                        value={item.quantity}
                                                        onChange={(e) => changeQuantityVariant(e.target.value, item, index)}
                                                    />
                                                    <div className='variant-up-down'>
                                                        <i
                                                            className='new-ldicon-arrow-up'
                                                            onClick={() => changeQuantityVariant(item.quantity + 1, item, index)}
                                                        />
                                                        <i
                                                            className='new-ldicon-arrow-down'
                                                            onClick={() => changeQuantityVariant(item.quantity - 1, item, index)}
                                                        />
                                                    </div>
                                                </>
                                            </div>
                                        ) : (
                                            <span>{item.quantity}</span>
                                        )}
                                    </div>
                                    <div className='variant-price-discount'>
                                        {orderData.order_id && item.discount &&
                                            <PopoverDiscountDetail
                                                discount={item.discount}
                                                remove={() => removeVariantDiscount(index)}
                                            >
                                                <a className='ladiui-custom-popup-init'>
                                                    {baseHelper.formatMoneyPostFix(
                                                        getLastPrice(item),
                                                        storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                                </a>
                                            </PopoverDiscountDetail>
                                        }
                                        {orderData.order_id && !item.discount &&
                                            baseHelper.formatMoneyPostFix(
                                                getLastPrice(item),
                                                storeReducer.userInfo.currentStore.currency_symbol
                                            )
                                        }
                                        {!orderData.order_id &&

                                            <PopoverDiscount
                                                discount={item.discount}
                                                apply={(discount) => applyVariantDiscount(index, discount)}
                                                remove={() => removeVariantDiscount(index)}
                                            >
                                                <a className='ladiui-custom-popup-init'>
                                                    {baseHelper.formatMoneyPostFix(
                                                        getLastPrice(item),
                                                        storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                                </a>
                                            </PopoverDiscount>
                                        }
                                        {item.discount && item.discount.value > 0 && (
                                            <span className='price-before-discount'>
                                                {baseHelper.formatMoneyPostFix(
                                                    item.price,
                                                    storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        )}
                                    </div>
                                    <div className='variant-total ladiui text-14'>
                                        {baseHelper.formatMoneyPostFix(
                                            getLastPrice(item) * item.quantity,
                                            storeReducer.userInfo.currentStore.currency_symbol
                                        )}
                                    </div>
                                    {isEditableOrder && (
                                        <div className='variant-delete' onClick={() => changeQuantityVariant(0, item, index, true)}>
                                            <i className='ladiui icon-new new-ldicon-delete' />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}

                {!isEditableOrder && orderData.status == appConfig.ORDER_STATUS.OPEN.CODE && (
                    // orderData.shipping_status != appConfig.SHIPPING_STATUS.CANCELED.CODE && (
                    <div className='payment-action ladiui flex-center-end footer'>
                        {/* cho giao hàng khi đơn pending hoặc đã huỷ giao hàng cũ và chưa trả lại hết hàng  */}
                        {(orderData.shipping_status == appConfig.SHIPPING_STATUS.PENDING.CODE ||
                            orderData.shipping_status == appConfig.SHIPPING_STATUS.CANCELED.CODE) &&
                            orderData.restock_all == appConfig.STATUS.INACTIVE ? (
                            <button className={`ladiui btn btn-sm btn-primary`} onClick={() => setShowModalShipping(true)}>
                                {t('SHIPPINGS.LABEL')}
                            </button>
                        ) : (
                            orderData.shipping_status != appConfig.SHIPPING_STATUS.CANCELED.CODE &&
                            orderData.restock_all == appConfig.STATUS.INACTIVE && (
                                <button
                                    className={`ladiui btn btn-sm btn-outline-danger`}
                                    onClick={() => window.LadiUI.showModal('confirm-shipping-cancel')}
                                >
                                    {t('SHIPPINGS.CANCEL')}
                                </button>
                            )
                        )}
                    </div>
                )}
            </div>

            <ConfirmModal
                id={`confirm-shipping-cancel`}
                title={t('SHIPPINGS.MSG_CANCEL_SHIPPING_TITLE')}
                content={t('SHIPPINGS.MSG_CANCEL_SHIPPING_CONTENT')}
                cancelText={t('ACTIONS.CLOSE')}
                okText={t('ACTIONS.CANCEL')}
                onOk={() => {
                    dispatch(shippingActions.updateStatus(shippingInfo.shipping_id, appConfig.SHIPPING_STATUS.CANCELED.CODE));
                }}
                isLoading={isLoadingShipping}
                maxWidth={600}
            />
            {isShowModalTicket && productSelected && (
                <ModalTicketSeat
                    productSelected={productSelected}
                    orderData={orderData}
                    visible={isShowModalTicket}
                    hideModal={() => setShowModalTicket(false)}
                    showReSend={() => setShowModalConfirmResendTicket(true)}
                    setShowModalTicketEditCustomer={setShowModalTicketEditCustomer}
                />
            )}
            {isShowModalTicketEditCustomer && (
                <ModalTicketSeatEditCustomer
                    visible={isShowModalTicketEditCustomer}
                    hideModal={() => setShowModalTicketEditCustomer(false)}
                    _customer={ticketCustomerSelected}
                />
            )}

            {isShowModalConfirmResendTicket && (
                <ConfirmModal
                    id='confirm-resend-ticket'
                    className='visible'
                    content={'Bạn có đồng ý gửi lại danh sách mã vé của đơn hàng này ?'}
                    title={'Thông báo'}
                    onOk={confirmResendTicket}
                    onCancel={() => setShowModalConfirmResendTicket(false)}
                    cancelText={'Hủy'}
                    okText={'Đồng ý'}
                    isLoading={isLoadingConfirmResendTicket}
                />
            )}
        </>
    );
});
