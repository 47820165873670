import i18n from '../../i18n';
import appConfig from '../../config/app';

export default {
    ORDER_TRACKING_LIST: {
        IP: {
            field: 'ip',
            name: i18n.t('ORDERS.IP'),
        },
        URL_PAGE: {
            field: 'url_page',
            name: i18n.t('ORDERS.URL_PAGE'),
        },
        UTM_SOURCE: {
            field: 'utm_source',
            name: i18n.t('ORDERS.UTM_SOURCE'),
        },
        UTM_MEDIUM: {
            field: 'utm_medium',
            name: i18n.t('ORDERS.UTM_MEDIUM'),
        },
        UTM_CAMPAIGN: {
            field: 'utm_campaign',
            name: i18n.t('ORDERS.UTM_CAMPAIGN'),
        },
        UTM_TERM: {
            field: 'utm_term',
            name: i18n.t('ORDERS.UTM_TERM'),
        },
        UTM_CONTENT: {
            field: 'utm_content',
            name: i18n.t('ORDERS.UTM_CONTENT'),
        },
        MSG_ID: {
            field: 'msg_id',
            name: i18n.t('ORDERS.MSG_ID'),
        },
        REF: {
            field: 'ref',
            name: i18n.t('ORDERS.REF'),
        },
        AD_ID: {
            field: 'ad_id',
            name: i18n.t('ORDERS.AD_ID'),
        },
        FB_ID: {
            field: 'fb_id',
            name: i18n.t('ORDERS.FB_ID'),
        },
        FB_PSID: {
            field: 'ladi_fb_psid',
            name: 'Facebook PSID'//i18n.t('ORDERS.FB_ID'),
        },
        FB_PAGE_ID: {
            field: 'ladi_fb_page_id',
            name: 'Facebook Page ID'//i18n.t('ORDERS.FB_ID'),
        },
    },

    getShippingStatus: (status) => {
        switch (status) {
            case appConfig.SHIPPING_STATUS.CANCELED.CODE:
                return appConfig.SHIPPING_STATUS.CANCELED.NAME;
            case appConfig.SHIPPING_STATUS.DELIVERED.CODE:
                return appConfig.SHIPPING_STATUS.DELIVERED.NAME;
            case appConfig.SHIPPING_STATUS.DELIVERED_TO_POSTMAN.CODE:
                return appConfig.SHIPPING_STATUS.DELIVERED_TO_POSTMAN.NAME;
            case appConfig.SHIPPING_STATUS.DELIVERING.CODE:
                return appConfig.SHIPPING_STATUS.DELIVERING.NAME;
            case appConfig.SHIPPING_STATUS.PENDING.CODE:
                return appConfig.SHIPPING_STATUS.PENDING.NAME;
            case appConfig.SHIPPING_STATUS.READY_TO_PICK.CODE:
                return appConfig.SHIPPING_STATUS.READY_TO_PICK.NAME;
            case appConfig.SHIPPING_STATUS.RETURNED.CODE:
                return appConfig.SHIPPING_STATUS.RETURNED.NAME;
            case appConfig.SHIPPING_STATUS.RETURNING.CODE:
                return appConfig.SHIPPING_STATUS.RETURNING.NAME;
            default:
                return appConfig.SHIPPING_STATUS.PENDING.NAME;
        }
    },
    getPaymentStatus: (status) => {
        switch (status) {
            case appConfig.PAYMENT_STATUS.PENDING.CODE:
                return appConfig.PAYMENT_STATUS.PENDING.NAME;
            case appConfig.PAYMENT_STATUS.SUCCESS.CODE:
                return appConfig.PAYMENT_STATUS.SUCCESS.NAME;
            default:
                return appConfig.PAYMENT_STATUS.PENDING.NAME;
        }
    },
    getPaymentType: (type) => {
        const matched = appConfig.SN_PAYMENT_METHODS.find((item) => item.CODE == type);
        if (matched) {
            return matched.NAME;
        }
        return '';
    },
    getRestQuantity(variant) {
        switch (variant.product_type) {
            case appConfig.PRODUCT_TYPE.EVENT.CODE:
                return variant.rest_quantity;
            case appConfig.PRODUCT_TYPE.SERVICE.CODE:
                if (variant.inventory_checked) return variant.rest_quantity;
                return -1;
            case appConfig.PRODUCT_TYPE.PHYSICAL.CODE:
            case appConfig.PRODUCT_TYPE.F_B.CODE:
            case appConfig.PRODUCT_TYPE.COMBO.CODE:
                if (variant.inventory_checked) return variant.rest_quantity;
                if (variant.allow_sold_out == appConfig.STATUS.ACTIVE) return -1;
                return 0;
        }
        return -1;
    },
};
