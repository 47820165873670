import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import PopperCheckout from './components/PopperCheckout';
import Dropdown from '../../../components/Dropdown';

import printActions from '../../../redux/futures/print/actions';
import { includes, isEmpty } from 'lodash';

// eslint-disable-next-line max-lines-per-function
function Header(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const {
        isGenerateCheckout,
        variantSelected,
        customerSelected,
        getLinkCheckout,
        checkoutLink,
        isEditableOrder,
        actionOrther,
        orderData,
        fullSubmit,
        partialSubmit,
        createDraft,
    } = props;
    return (
        <div className='header-o'>
            <div className='header'>
                <div className='ladiui order-breadcrumbs'>
                    <span
                        className='back-btn'
                        onClick={() => {
                            // if (history.length > 1) history.goBack();
                            // else
                            history.push('/orders');
                        }}
                    >
                        {t('ORDERS.TITLE')}
                    </span>
                    <i className='icon ldicon-chevron-right' />
                    {orderData && orderData.order_id ? (
                        <span className='ladiui label-16'>Thông tin đơn hàng</span>
                    ) : (
                        <span className='ladiui label-16'> {t('ORDERS.ORDER_CREATE_LABEL')} </span>
                    )}
                </div>
                <div className='order-head-actions'>
                    {orderData && orderData.order_id && (
                        <div>
                            {/* {isGenerateCheckout ? ( */}
                            <PopperCheckout
                                checkoutLink={checkoutLink}
                                getLinkCheckout={getLinkCheckout}
                            // isGenerateCheckout={isGenerateCheckout}
                            />
                            {/* ) : (
                                <button
                                    className={`ladiui btn btn-md btn-secondary display-flex-center ${variantSelected.length == 0 || !customerSelected.customer_id ? 'disable' : ''
                                        }`}
                                    onClick={getLinkCheckout}
                                >
                                    <i className='ldicon-link mr-8' />
                                    {t('ORDERS.GET_LINK_CHECKOUT_CREATE')}
                                </button>
                            )} */}
                        </div>
                    )}
                    {!isEditableOrder && orderData.order_id && (
                        <>
                            <button
                                className={`ladiui btn btn-md btn-secondary display-flex-center ${variantSelected.length == 0 || !customerSelected.customer_id ? 'disable' : ''
                                    }`}
                                onClick={() => {
                                    dispatch(printActions.printOrder(orderData.order_id));
                                }}
                            >
                                <i className='ldicon-printer mr-8' />
                                {t('ORDERS.PRINT_ORDER')}
                            </button>

                            <Dropdown
                                data={appConfig.ORDER_EDIT_OTHER_ACTION.filter((item) => {
                                    if (
                                        (orderData.status == appConfig.ORDER_STATUS.CANCELED.CODE || orderData.mark_spam) &&
                                        item.type == 'ORDER_SPAM'
                                    )
                                        return false;
                                    if (
                                        (orderData.payment_status != appConfig.PAYMENT_STATUS.SUCCESS.CODE || isEmpty(customerSelected.email)) &&
                                        item.type == 'ORDER_RESENT_MAIL'
                                    )
                                        return false;
                                    if (
                                        (orderData.status == appConfig.ORDER_STATUS.CANCELED.CODE || !orderData.mark_spam) &&
                                        item.type == 'ORDER_SPAM_CANCEL'
                                    )
                                        return false;
                                    if (
                                        (orderData.status == appConfig.ORDER_STATUS.CANCELED.CODE ||
                                            ![appConfig.SHIPPING_STATUS.PENDING.CODE, appConfig.SHIPPING_STATUS.CANCELED.CODE].includes(
                                                orderData.shipping_status
                                            )) &&
                                        ['ORDER_CANCEL'].includes(item.type)
                                    )
                                        return false;

                                    if (
                                        ![appConfig.SHIPPING_STATUS.PENDING.CODE, appConfig.SHIPPING_STATUS.CANCELED.CODE].includes(
                                            orderData.shipping_status
                                        ) &&
                                        ['ORDER_RE_ORDER'].includes(item.type)
                                    )
                                        return false;

                                    return true;
                                })}
                                placeHolder={t('ACTIONS.ORTHER')}
                                onSelectItem={(item) => {
                                    actionOrther(item);
                                }}
                                innerClassName='text-primary'
                                isSecondary={true}
                                _key='type'
                                _value='name'
                            />
                        </>
                    )}
                    {orderData && orderData.order_id ? (
                        <>
                            <button
                                className={`ladiui btn btn-md btn-primary ${variantSelected.length == 0 || !customerSelected.customer_id ? 'disable' : ''
                                    }`}
                                onClick={() => {
                                    if (isEditableOrder) fullSubmit();
                                    else partialSubmit();
                                }}
                            >
                                {t('ORDERS.SAVE')}
                            </button>
                        </>
                    ) : (
                        <button
                            className={`ladiui btn btn-md btn-primary ${variantSelected.length == 0 || !customerSelected.customer_id ? 'disable' : ''
                                }`}
                            onClick={createDraft}
                        >
                            {t('ORDERS.SAVE_DRAFT_ORDER')}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Header;
