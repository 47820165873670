import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { I18nContext, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import PanelSettingMenuBanner from './components/updates/PanelSettingMenuBanner';
import PanelCourseCatalog from './components/PanelCourseCatalog';
import PanelSettingFooterPage from './components/PanelSettingFooterPage';
import PanelSettingNotification from './components/PanelSettingNotification';
import PanelSettingDomainCourse from './components/updates/PanelSettingDomainCourse';
// import PanelStudentManagement from "./components/PanelStudentManagement";

import pageCheckoutActions from '../../redux/futures/page_checkout/actions';

import * as pageCheckoutTypes from '../../redux/futures/page_checkout/types';

import LoadingScene from '../../components/LoadingScene';

import ValidatorHelper from '../../helpers/ValidatorHelper';
import { compact, map, find, debounce, findIndex, includes } from 'lodash';

import baseHelper from '../../helpers/BaseHelper';
import Axios from "axios";
import appConfig from "../../config/app";
import PanelCourseSettingTracking from './components/PanelCourseSettingTracking';
import PanelSettingDomainCourseTab from './components/updates/PanelSettingDomainCourseTab';

const PageCourse = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    let hash = window.location.hash;
    if (!hash) {
        hash = 'DOMAIN_SETTING';
    } else {
        hash = hash.replace('#', '');
    }

    const fileReducer = useSelector((state) => state.file);

    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

    const [tabSelected, setTabSelected] = useState(hash);

    const [currentDataMenu, setCurrentDataMenu] = useState({});
    const [currentDataDomain, setCurrentDataDomain] = useState({});
    const [pageCheckoutId, setPageCheckoutId] = useState(null);
    const [currentCourseCatalog, setCurrentCourseCatalog] = useState({});
    const [currentDataSeo, setCurrentDataSeo] = useState({});
    const [currentDataFooter, setCurrentDataFooter] = useState({});
    const [isUpdateCourse, setIsUpdateCourse] = useState(false);
    const [selectedPath, setSelectedPath] = useState('');
    const [currentPagePublicType, setCurrentPagePublicType] = useState('');
    const [currentFreeCourseLabel, setCurrentFreeCourseLabel] = useState('');
    const [currentLabelAccountConfigs, setCurrentLabelAccountConfigs] = useState(null);
    const [currentTrackingData, setCurrentTrackingData] = useState(null);
    const [currentLadiShareStore, setCurrentLadiShareStore] = useState(null);
    const [currentAffiliateStatus, setCurrentAffiliateStatus] = useState(false);

    const [listBanners, setListBanners] = useState([]);

    const [suggestedCategories, setSuggestedCategories] = useState([]);

    const [selectedLogo, setSelectedLogo] = useState('');

    const [isPreviewPageCourse, setIsPreviewPageCourse] = useState(false);

    const [fontList, setFontList] = useState([]);

    const inputsRef = new Set();

    const panelSettingMenuBannerRef = React.createRef();
    const panelCourseCatalogRef = React.createRef();
    const panelSettingFooterPageRef = React.createRef();
    const productSeoSocialRef = React.createRef();
    const panelDomainCourseRef = React.createRef();
    const panelNotificationCourseRef = React.createRef();
    const panelStudentManagementRef = React.createRef();
    const panelCourseSettingTrackingRef = React.createRef();
    const panelDomainTabCourseRef = React.createRef();


    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {};
        },
    }));

    const fetchListFont = async () => {
        const apiUrl = `https://www.googleapis.com/webfonts/v1/webfonts?key=${appConfig.GOOGLE_FONT_API_KEY}&subset=vietnamese&capability=VF`;

        try {
            const result = await Axios.get(apiUrl);
            const vietnameseFonts = result?.data?.items ? result?.data?.items?.filter(font => font.subsets.includes('vietnamese') && ['sans-serif', 'serif']?.includes(font.category)) : [];

            setFontList(vietnameseFonts || []);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchListFont();
        dispatch(pageCheckoutActions.pageCourseShow());
    }, []);

    useEffect(() => {
        if (pageCheckoutReducer.action == pageCheckoutTypes.PAGE_COUSE_SHOW) {
            if (pageCheckoutReducer.status) {
                if (pageCheckoutReducer.dataPageCheckout) {
                    let pageCheckout = pageCheckoutReducer?.dataPageCheckout;
                    setCurrentPagePublicType(pageCheckoutReducer.dataPageCheckout?.config_course?.page_public_type);
                    setCurrentFreeCourseLabel(pageCheckoutReducer.dataPageCheckout?.config_course?.free_course_label);
                    setCurrentLabelAccountConfigs(pageCheckoutReducer.dataPageCheckout?.config_course?.label_account_configs);
                    setCurrentAffiliateStatus(pageCheckoutReducer.dataPageCheckout?.config_course?.affiliate_status || false);
                    setCurrentLadiShareStore(pageCheckoutReducer.dataPageCheckout?.ladishare_store);

                    if (pageCheckout.page_checkout_id) {
                        setPageCheckoutId(pageCheckout.page_checkout_id);
                    }
                    setCurrentDataMenu(pageCheckout.config_course.menu_banner);
                    setListBanners(pageCheckout.config_course.menu_banner.banners || []);

                    setSuggestedCategories(pageCheckout.config_course.menu_banner.suggested_categories || []);

                    setSelectedLogo(pageCheckout.config_course.menu_banner.logo || []);

                    setCurrentDataFooter(pageCheckout.config_course.footer);

                    setCurrentDataDomain({
                        checkout_config_id: pageCheckout.checkout_config_id,
                        domain: pageCheckout.domain,
                    });

                    setCurrentTrackingData({
                        facebook_pixel: pageCheckout?.config_course.tracking?.facebook_pixel || "",
                        google_analytics_id: pageCheckout?.config_course.tracking?.google_analytics_id || "",
                        google_ads_id: pageCheckout?.config_course.tracking?.google_ads_id || "",
                        google_ads_id_label: pageCheckout?.config_course.tracking?.google_ads_id_label || "",
                        tiktok_pixel: pageCheckout?.config_course.tracking?.tiktok_pixel || "",
                        google_tag_manager_id: pageCheckout?.config_course.tracking?.google_tag_manager_id || "",
                        custom_javascript_head: pageCheckout?.config_course.tracking?.custom_javascript_head || "",
                        custom_javascript_body: pageCheckout?.config_course.tracking?.custom_javascript_body || "",
                    });

                    setCurrentDataSeo(pageCheckout.seo);

                    setCurrentCourseCatalog({
                        selectedCourses: pageCheckout.featured_products,
                        config_category: pageCheckout.categories,
                    });
                    setSelectedPath(pageCheckout.path || '');
                    if (isPreviewPageCourse) {
                        if (pageCheckout.domain && !pageCheckout.domain.includes(appConfig.DOMAIN_COURSE_DEFAULT)) {
                            setTimeout(() => {
                                window.open(`https://${pageCheckout.domain}`, '_blank', 'noopener,noreferrer');
                            }, 2000);
                        } else {
                            setTimeout(() => {
                                window.open(`https://${appConfig.DOMAIN_COURSE_DEFAULT}/${pageCheckout.path}`, '_blank', 'noopener,noreferrer');
                            }, 2000);
                        }
                    }
                } else {
                    history.push('/page-course/welcome');
                    // window.location.replace('/page-course/welcome');
                }
            }
        }
        if (pageCheckoutReducer.action == pageCheckoutTypes.CREATE_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);
                dispatch(pageCheckoutActions.pageCourseShow());
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
        if (pageCheckoutReducer.action == pageCheckoutTypes.UPDATE_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                if (!isPreviewPageCourse) {
                    window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);
                }
                dispatch(pageCheckoutActions.pageCourseShow());
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }

        if (pageCheckoutReducer.action == pageCheckoutTypes.PAGE_COURSE_ACTIVE_AFFILIATE) {
            if (pageCheckoutReducer.status) {
                window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);
                setCurrentLadiShareStore(pageCheckoutReducer.dataLadishareStore);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
    }, [pageCheckoutReducer]);

    const getPayloadCategories = async (menuIds) => {
        let Menus = [];
        for await (let menu of menuIds) {
            let payloadItem = {
                product_category_id: menu.product_category_id,
                children: menu.children && menu.children.length > 0 ? await getPayloadCategories(menu.children) : [],
            };
            Menus.push(payloadItem);
        }

        return Menus;
    };

    const submitData = async () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        const dataMenuRef = panelSettingMenuBannerRef.current.getData();
        // const dataCourseCatalogRef = panelCourseCatalogRef.current.getData();
        // const dataFooterRef = panelSettingFooterPageRef.current.getData();
        const dataDomainSetting = panelDomainCourseRef.current.getData();
        const dataTracking = panelCourseSettingTrackingRef.current.getData();
        const dataDomainTab = panelDomainTabCourseRef.current.getData();

        let payloadCategoryMenuIds = [];

        if (dataMenuRef.menu_category_ids && dataMenuRef.menu_category_ids.length > 0) {
            payloadCategoryMenuIds = await getPayloadCategories(dataMenuRef.menu_category_ids);
        }
        let payloadSubmitData = {
            page_checkout: {
                checkout_config_id: dataDomainSetting?.checkout_config_id ? dataDomainSetting.checkout_config_id : null,
                domain: dataDomainTab?.domain ? dataDomainTab.domain : "",
                // domain: "",
                types: "Course",
                name: "Trang khóa học",
                // featured_product_ids: dataCourseCatalogRef?.featured_product_ids ? dataCourseCatalogRef.featured_product_ids : [],
                featured_product_ids: [],
                // config_category: dataCourseCatalogRef?.config_category ? dataCourseCatalogRef.config_category : [],
                config_category: [],
                seo: dataDomainSetting?.seo ? dataDomainSetting.seo : [],
                config_course: {
                    menu_banner: {
                        ...dataMenuRef,
                        menu_category_ids: payloadCategoryMenuIds || [],
                        logo: dataDomainSetting?.logo ? dataDomainSetting.logo : "",
                        // banners: dataCourseCatalogRef?.banners ? dataCourseCatalogRef.banners : [],
                        banners: [],
                        // suggested_category_ids: dataCourseCatalogRef?.suggested_category_ids ? dataCourseCatalogRef.suggested_category_ids : [],
                        suggested_category_ids: [],
                    },
                    footer: {
                        // ...dataFooterRef,
                    },
                    page_public_type: dataDomainSetting?.page_public_type,
                    free_course_label: dataDomainSetting?.free_course_label,
                    label_account_configs: dataDomainSetting?.label_account_configs,
                    tracking: dataTracking,
                    affiliate_status: dataDomainSetting?.affiliate_status
                },
            },

        };
        if (pageCheckoutId) {
            payloadSubmitData.page_checkout.page_checkout_id = pageCheckoutId;
            dispatch(pageCheckoutActions.update(payloadSubmitData));
        } else {
            dispatch(pageCheckoutActions.create(payloadSubmitData));
        }
    };

    const handleActiveAffiliatePageCourse = () => {
        dispatch(pageCheckoutActions.activeAffiliate());
    };

    const handleSubmitData = () => {
        setIsPreviewPageCourse(false);
        submitData();
    };

    const handlePreviewPageCourse = () => {
        submitData();
        setIsPreviewPageCourse(true);
    };

    const handleSetTabSelected = (value) => {
        window.location.hash = `#${value}`;
        setTabSelected(value);
    };

    const isLoadingSubmitCourse =
        pageCheckoutReducer.loading &&
        includes([pageCheckoutTypes.CREATE_PAGE_CHECKOUT, pageCheckoutTypes.UPDATE_PAGE_CHECKOUT, pageCheckoutTypes.PAGE_COURSE_ACTIVE_AFFILIATE], pageCheckoutReducer.waiting);

    return (
        <div className='page-content ls-pt-0'>
            {isLoadingSubmitCourse && <LoadingScene blur={true} />}
            <div className='header-page'>
                <div className='heading'>
                    <h1>{t('PAGE_COURSE.TITLE')}</h1>
                    <p>{t('PAGE_COURSE.SUB_TITLE')}</p>
                </div>
                <div className='button-action'>
                    <button
                        className='btn-export-order'
                        onClick={handlePreviewPageCourse}
                    >
                        {t('Xem trước')}
                    </button>
                    <button type='button' className='btn-order-create' onClick={handleSubmitData}>
                        <div className='ladiui btn-custom-text '>{t('Lưu trang khoá học')}</div>
                    </button>
                </div>
            </div>

            <div className='block-content ls-px-24'>
                <div className='order-tab'>
                    <ul className='ladiui nav nav-tabs overflow-hide' role='tab-order-all'>
                        <li
                            className='ladiui nav-item'
                            id='tabDuplication'
                            role='tab-order-all-item'
                            onClick={() => handleSetTabSelected('DOMAIN_SETTING')}
                        >
                            <span
                                className={`ladiui tab-link ${tabSelected === 'DOMAIN_SETTING' ? 'active' : ''}`}
                                data-toggle='tab'
                                id='triggerTabDuplication'
                                data-tab='tabDuplication'
                            >
                                {t('PAGE_COURSE.GENERAL_SETTING')}
                            </span>
                        </li>

                        <li
                            className='ladiui nav-item'
                            id='tabDuplication2'
                            role='tab-order-all-item-2'
                            onClick={() => handleSetTabSelected('MENU_BANNER')}
                        >
                            <span
                                className={`ladiui tab-link ${tabSelected === 'MENU_BANNER' ? 'active' : ''}`}
                                data-toggle='tab'
                                id='triggerTabDuplication2'
                                data-tab='tabDuplication2'
                            >
                                {t('PAGE_COURSE.MENU_SETTING')}
                            </span>
                        </li>
                        <li
                            className='ladiui nav-item'
                            id='tabDuplication3'
                            role='tab-order-all-item-3'
                            onClick={() => handleSetTabSelected('DOMAIN')}
                        >
                            <span
                                className={`ladiui tab-link ${tabSelected === 'DOMAIN' ? 'active' : ''}`}
                                data-toggle='tab'
                                id='triggerTabDuplication3'
                                data-tab='tabDuplication3'
                            >
                                {t('PAGE_COURSE.DOMAIN')}
                            </span>
                        </li>
                        <li
                            className='ladiui nav-item'
                            id='tabDuplication4'
                            role='tab-order-all-item-4'
                            onClick={() => handleSetTabSelected('NOTIFICATION')}
                        >
                            <span
                                className={`ladiui tab-link ${tabSelected === 'NOTIFICATION' ? 'active' : ''}`}
                                data-toggle='tab'
                                id='triggerTabDuplication4'
                                data-tab='tabDuplication4'
                            >
                                {t('PAGE_COURSE.NOTIFICATION')}
                            </span>
                        </li>
                        <li
                            className='ladiui nav-item'
                            id='tabDuplication5'
                            role='tab-order-all-item-5'
                            onClick={() => handleSetTabSelected('TRACKING')}
                        >
                            <span
                                className={`ladiui tab-link ${tabSelected === 'TRACKING' ? 'active' : ''}`}
                                data-toggle='tab'
                                id='triggerTabDuplication5'
                                data-tab='tabDuplication5'
                            >
                                {t('PAGE_COURSE.TRACKING')}
                            </span>
                        </li>
                    </ul>
                </div>

                <div className='mt-24'>
                    <PanelSettingMenuBanner
                        isShow={tabSelected == 'MENU_BANNER'}
                        handleSubmitData={handleSubmitData}
                        currentDataMenu={currentDataMenu || {}}
                        ref={panelSettingMenuBannerRef}
                        handlePreviewPageCourse={handlePreviewPageCourse}
                        fontList={fontList}
                    />
                    <PanelSettingDomainCourse
                        isShow={tabSelected == 'DOMAIN_SETTING'}
                        currentDataDomain={currentDataDomain || {}}
                        currentDataSeo={currentDataSeo || {}}
                        selectedLogo={selectedLogo}
                        handleSubmitData={handleSubmitData}
                        ref={panelDomainCourseRef}
                        selectedPath={selectedPath}
                        handlePreviewPageCourse={handlePreviewPageCourse}
                        currentPagePublicType={currentPagePublicType}
                        currentFreeCourseLabel={currentFreeCourseLabel}
                        currentLabelAccountConfigs={currentLabelAccountConfigs}
                        currentLadiShareStore={currentLadiShareStore}
                        currentAffiliateStatus={currentAffiliateStatus}
                        handleActiveAffiliatePageCourse={handleActiveAffiliatePageCourse}
                    />
                    <PanelSettingNotification
                        isShow={tabSelected == 'NOTIFICATION'}
                        ref={panelNotificationCourseRef}
                    />

                    <PanelSettingDomainCourseTab
                        isShow={tabSelected == 'DOMAIN'}
                        ref={panelDomainTabCourseRef}
                        currentDataDomain={currentDataDomain || {}}
                        selectedPath={selectedPath}
                    />

                    <PanelCourseSettingTracking
                        isShow={tabSelected == 'TRACKING'}
                        ref={panelCourseSettingTrackingRef}
                        currentTrackingData={currentTrackingData}
                    />
                </div>
            </div>
        </div>
    );
});
PageCourse.propTypes = {
    titlePage: PropTypes.string,
    descriptionPage: PropTypes.string,
    img_publish: PropTypes.string,
    favicon: PropTypes.string,
};

export default PageCourse;
