const config = {
    // LS
    API_V2_0: "https://apiv4.sales.ldpform.net/2.0",
    WS_API_CHECKOUT_V1_0: 'https://api.checkout.ladisales.com',
    DOMAIN_DNS: 'https://checkout.ladi.sale',

    // UID
    API_UID_V2_0: "https://api.ladiuid.com/2.0",
    ACCOUNT_UID: "https://accounts.ladiuid.com",
    
    // FILE
	API_FILE_V2_0: "https://api.files.ladicdn.com/2.0",
    BUILDER_LDP: 'https://builder.ladipage.com'
}

const authEndpoint = {
    AUTH_SIGNIN: `${config.ACCOUNT_UID}/signin?callback_url=https://appv4.ladisales.com&app_code=ls`,
    AUTH_SIGNUP: `${config.ACCOUNT_UID}/signup?callback_url=https://appv4.ladisales.com&app_code=ls`,
    AUTH_SIGNOUT: `${config.ACCOUNT_UID}/signin?logout=1&callback_url=https://appv4.ladisales.com&app_code=ls`,
}

export default config;

export {
    authEndpoint
}

