/** @format */

import React from 'react';

import { connect } from 'react-redux';

import * as productTypes from '../../redux/futures/product/types';
import productActions from '../../redux/futures/product/actions';

import * as variantTypes from '../../redux/futures/product_variant/types';
import variantActions from '../../redux/futures/product_variant/actions';

import { find, includes, map, remove } from 'lodash';
import appConfig from '../../config/app';
import baseHelper from '../../helpers/BaseHelper';

import ModalEdit from './components/ModalEdit/ModalEditProduct';
import LoadingTable from '../../components/LoadingTable';
import { withTranslation } from 'react-i18next';
import ConfirmModal from '../../components/ConfirmModal';
import LoadingScene from '../../components/LoadingScene';
import { appLocalStorage } from '../../localforage';
import queryString from 'query-string';

import * as productTagTypes from '../../redux/futures/product_tag/types';
import productTagActions from '../../redux/futures/product_tag/actions';

import storePageActions from '../../redux/futures/store_page/actions';
import storePageTypes from '../../redux/futures/store_page/types';

import Tag from '../../components/Tag';

import ModalExportCustomer from './components/ModalExportProduct';

import * as productCategoryTypes from '../../redux/futures/product_category/types';
import productCategoryActions from '../../redux/futures/product_category/actions';

import * as filterTypes from '../../redux/futures/filter/types';
import filterActions from '../../redux/futures/filter/actions';

import produce from 'immer';
import ModalExportProduct from './components/ModalExportProduct';
import ModalExportEvent from '../events/components/ModalExportEvent';
import ModalExportService from '../services/components/ModalExportService';
import FilterConditionProduct from './components/FilterConditionProduct';
import LadiPagination from '../../components/LadiPagination';
import LadiDropdownMenu from '../../components/LadiDropdownMenu';
import ModalSelectProduct from './components/ModalSelectProduct';
import ListTags from '../../components/ListTags';

import Dropdown from '../../components/Dropdown';

import FilterConditionOrderv2 from '../orders/components/FilterCondition/FilterConditionOrderv2';

import ModalSaveCondition from '../orders/components/FilterCondition/ModalSaveCondition';
import ModalRename from '../orders/components/FilterCondition/ModalRename';

import ModalExportProductByType from '../../components/ModalExportProductByType';
import ModalImportProductByType from '../../components/ModalImportProductByType';
import config from '../../config/config';

class Product extends React.Component {
    constructor(props) {
        super(props);
        const defaultConditions = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        };

        this.state = {
            activePage: 1,

            searchProductName: '',
            selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            currentProductInfo: {},
            isShowModalCreate: false,
            isShowModalEdit: false,
            userInfo: {},
            isShowModalExportProduct: false,
            selectedIDs: [],
            conditions: defaultConditions,
            productType: '',
            tag_ids: [],
            product_category_ids: [],
            page_checkout_id: [],
            type: '',
            isShowModalExportEvent: false,
            isShowModalExportService: false,
            isShowFilterCondition: false,
            isShowModalSaveCondition: false,
            dataConditions: {
                operator: appConfig.OPERATORS.SWITCH_AND_OR.AND,
                conditions: [],
            },
            conditionProp: {
                status: {
                    value: appConfig.ORDER_STATUS.OPEN.CODE,
                    name: appConfig.ORDER_STATUS.OPEN.NAME,
                    condition: '=',
                },
            },
            search_v2: {},
            filterCustom: [],
            filterCustomShow: [],
            filterCustomDropdown: [],
            tabSelected: null,
            filterAction: {},
            isShowModalFilterRename: false,
            isShowModalExportData: false,
            isShowModalImportData: false,
        };

        this.selectedID = '';
        this.selectedProduct = {};

        this.sortBy = {
            product_id: 'DESC',
        };
        this.searchTagRef = React.createRef();
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            this.setState({
                userInfo: result,
            });
        });
    }

    componentDidMount() {
        this.list();

        let { product_id: productID, variant_id: variantID, type, open_create_popup } = queryString.parse(this.props.location.search);
        if (productID && type) {
            let url = this.handelProductEdit(type, productID);
            window.open(url, '_parent');
            return;
        }

        this.props.listAllTags();

        let payload = {
            search: {
                type: 'Product',
            },
        };
        this.props.listAllCategories(payload);

        const payloadListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        this.props.listStores(payloadListStore);

        this.props.filterList({ type: 'PRODUCT' });

        const { isShowMessageErr, message } = queryString.parse(this.props.location.search);

        if (isShowMessageErr && message) {
            window.LadiUI.showErrorMessage('Thông báo', message, 'OK');
        }
        if (open_create_popup) {
            this.openModalCreate();
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
        setTimeout(() => {
            window.LadiUI.customInit();
        }, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.product.action != nextProps.product.action) {
            if (nextProps.product.action === productTypes.RELOAD_PRODUCT) {
                if (nextProps.product.status) {
                    this.setState({
                        currentProductInfo: nextProps.product.product,
                    });
                }
            }

            if (nextProps.product.action === productTypes.UPDATE_PRODUCT) {
                if (nextProps.product.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.product.message);
                    this.props.reload(this.state.currentProductInfo.product_id);
                    this.list();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
                }
            }

            if (includes([productTypes.DELETE_PRODUCT, productTypes.DELETE_PRODUCTS], nextProps.product.action)) {
                if (nextProps.product.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.product.message);

                    this.list();

                    window.LadiUI.closeModal('confirm-product-delete');
                    window.LadiUI.closeModal('confirm-product-deletes');
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
                }
            }

            // if (nextProps.product.action == productTypes.LIST_PRODUCT) {
            //   if (nextProps.product.status) {
            //     window.LadiUI.forEach(window.LadiUI.querySelector(".ladiui table .sorting"), (item) => {
            //       item.classList.remove("up");

            //       if (this.sortBy[item.getAttribute("name")] == "DESC") {
            //         item.classList.add("up");
            //       }
            //     });
            //   } else {
            //     window.LadiUI.showErrorMessage("Thông báo", nextProps.product.message, "OK");
            //   }
            // }

            if (nextProps.product.action == productTypes.LIST_ALL_PRODUCT) {
                if (nextProps.product.status) {
                    window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
                        item.classList.remove('up');

                        if (this.sortBy[item.getAttribute('name')] == 'DESC') {
                            item.classList.add('up');
                        }
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
                }
            }

            if (nextProps.product.action == productTypes.IMPORT_PRODUCT || nextProps.product.action == productTypes.IMPORT_SERVICE || nextProps.product.action == productTypes.IMPORT_EVENT) {
                if (nextProps.product.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.product.message);
                    this.setState({
                        isShowModalImportData: false,
                    });
                    this.list();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
                }
            }

            if (nextProps.product.action == productTypes.EXPORT_PRODUCT) {
                if (nextProps.product.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.product.message);
                    this.setState({
                        isShowModalExportData: false,
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
                }
            }
        }

        if (this.props.variant.action != nextProps.variant.action) {
            if (nextProps.variant.action == variantTypes.GET_VARIANT_INFOS) {
                if (nextProps.variant.status) {
                    const variantInfos = map(nextProps.variant.variantInfos, (item) => {
                        const variantName = `${item.product_variant_id}:1|${this.selectedProduct.name} ${item.name ? `(${item.name})` : ''
                            }`;
                        return variantName;
                    });
                    const variantInfo = variantInfos.join('\r\n');
                    baseHelper.copyToClipboard(variantInfo);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.variant.message, 'OK');
                }
            }
        }

        if (this.props.filterReducer.action != nextProps.filterReducer.action) {
            // if (nextProps.filterReducer.action == filterTypes.FILTER_CREATE) {
            //   if (nextProps.filterReducer.status) {
            //     this.props.filterList({ type: 'ORDER' });
            //     this.setState({
            //       isShowModalSaveCondition: false,
            //     });
            //   } else {
            //     this.setState({
            //       filterCustom: [],
            //       filterCustomShow: [],
            //       filterCustomDropdown: [],
            //     });
            //   }
            // }

            if (nextProps.filterReducer.action == filterTypes.FILTER_LIST) {
                if (nextProps.filterReducer.status) {
                    let filters = nextProps.filterReducer.filters;

                    // Bỏ đoạn filter.splice bên dưới đi thì chạy
                    let filterCustomShow = filters.splice(0, 4);
                    let filterCustomDropdown = [...filters];
                    filters = [...filterCustomShow, ...filterCustomDropdown];
                    this.setState(
                        {
                            filterCustom: filters,
                            filterCustomShow,
                            filterCustomDropdown,
                        },
                        () => {
                            if (!this.state.tabSelected) {
                                let queryStrings = new URLSearchParams(this.props.location.search);
                                let productTab = queryStrings.get('product_tab');
                                if (!productTab) productTab = appConfig.ORDER_FILTER_TYPE.ALL;
                                this.onChangeTab(productTab);
                            }
                        }
                    );
                } else {
                    this.setState({
                        filterCustom: [],
                        filterCustomShow: [],
                        filterCustomDropdown: [],
                    });
                }
            }
            if (nextProps.filterReducer.action == filterTypes.FILTER_CREATE) {
                if (nextProps.filterReducer.status) {
                    this.props.filterList({ type: 'PRODUCT' });
                    this.setState({
                        isShowModalSaveCondition: false,
                    });
                    window.LadiUI.toastCustom('success', '', nextProps.filterReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.filterReducer.message, 'OK');
                }
            }
            if (nextProps.filterReducer.action == filterTypes.FILTER_DELETE) {
                if (nextProps.filterReducer.status) {
                    this.props.filterList({ type: 'PRODUCT' });
                    window.LadiUI.closeModal('confirm-remove-filter');
                    window.LadiUI.toastCustom('success', '', nextProps.filterReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.filterReducer.message, 'OK');
                }
            }
            if (nextProps.filterReducer.action == filterTypes.FILTER_UPDATE) {
                if (nextProps.filterReducer.status) {
                    this.props.filterList({ type: 'PRODUCT' });
                    this.setState({
                        isShowModalFilterRename: false,
                        isShowModalSaveCondition: false,
                    });
                    window.LadiUI.toastCustom('success', '', nextProps.filterReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.filterReducer.message, 'OK');
                }
            }
            if (nextProps.filterReducer.action == filterTypes.FILTER_DUPLICATE) {
                if (nextProps.filterReducer.status) {
                    // window.LadiUI.closeModal('confirm-remove-filter');
                    window.LadiUI.toastCustom('success', '', nextProps.filterReducer.message);

                    this.props.filterList({ type: 'PRODUCT' });

                    this.setState({
                        isShowModalFilterRename: false,
                        isShowModalSaveCondition: false,

                        isShowModalColumnFilter: false,
                        isShowModalSaveCondition: false,
                        filterActions: {},
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.filterReducer.message, 'OK');
                }
            }
        }
    }

    /**
     * list product
     */
    list = (resetActivePageFlag = true) => {
        if (document.getElementById('checkAllItem')) {
            document.getElementById('checkAllItem').checked = false;
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        const { searchProductName, selectedLimit, conditions, search_v2 } = this.state;
        let activePage = conditions.page;

        if (resetActivePageFlag) {
            activePage = 1;
            conditions.page = 1;

            this.setState({
                conditions: conditions,
            });
        }

        // Reset selectedIDs
        this.setState({
            selectedIDs: [],
        });
        const tagIDs = this.state.tag_ids ? this.state.tag_ids : [];
        const productCategoryIds = this.state.product_category_ids ? this.state.product_category_ids : [];
        const pageCheckoutId = this.state.page_checkout_id ? this.state.page_checkout_id : [];
        // const tagIDs = this.searchTagRef.current.getIDs();

        const data = {
            search_v2,
            search: {
                name: searchProductName,
                // tag_ids: tagIDs,
                // product_category_ids: productCategoryIds,
                // page_checkout_id: pageCheckoutId,
                type: this.state.type,
            },
            paged: activePage,
            limit: conditions.limit,
            sort: this.sortBy,
        };

        this.props.listAllProducts(data);
    };

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    };

    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        let { conditions } = this.state;
        conditions.limit = option.value;

        // this.setState({ selectedLimit: option.value }, () => {
        this.setState({ conditions: conditions }, () => {
            this.list();
        });
    };

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        let { conditions } = this.state;
        conditions.page = pageNumber;

        this.setState({ conditions }, () => {
            this.list(false);
        });
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        let selectedIDs = [];
        if (checked) {
            selectedIDs = map(this.props.product.products, (product) => product.product_id);

            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        this.setState({
            selectedIDs,
        });
    };

    checkItem = (event, productID) => {
        const { checked } = event.target;
        const { selectedIDs } = this.state;

        if (checked) {
            if (!includes(selectedIDs, productID)) {
                selectedIDs.push(productID);
            }

            if (selectedIDs.length == this.props.product.products.length) {
                document.getElementById('checkAllItem').checked = true;
            }
        } else {
            document.getElementById('checkAllItem').checked = false;
            remove(selectedIDs, (selectedProductID) => selectedProductID == productID);
        }

        this.setState({
            selectedIDs,
        });
    };

    deletes = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        this.props.deletes(this.state.selectedIDs);
    };

    deleteItem = (productID) => {
        this.props.delete(productID);
    };

    sort = (event) => {
        const { classList } = event.target;

        const name = event.target.getAttribute('name');

        window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
            if (item.getAttribute('name') != name) {
                item.classList.remove('up');
            }
        });

        this.sortBy = {};

        // Change arrow up and down
        if (includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }
        this.setState({}, () => this.list());
    };

    openModalCreate = () => {
        this.setState({
            isShowModalCreate: true,
            currentProductInfo: null,
        });
    };

    hideModalCreate = () => {
        this.setState({
            isShowModalCreate: false,
        });
    };

    openModalEdit = (productID) => {
        // this.props.show(productID);
        this.props.history.push(`/products/update/${productID}`);
    };

    handelProductEdit = (type, productID) => {
        let product_type = 'products';

        switch (type) {
            case appConfig.PRODUCT_TYPE.SERVICE.CODE:
                // window.open(`/services/update/${productID}`, '_blank', 'noopener,noreferrer');
                product_type = 'services';
                break;
            case appConfig.PRODUCT_TYPE.DIGITAL.CODE:
                product_type = 'digital';
                // window.open(`/digital/update/${productID}`, '_blank', 'noopener,noreferrer');
                break;
            case appConfig.PRODUCT_TYPE.EVENT.CODE:
                product_type = 'events';
                // window.open(`/events/update/${productID}`, '_blank', 'noopener,noreferrer');
                break;
            default:
                // window.open(`/products/update/${productID}`, '_blank', 'noopener,noreferrer');
                break;
        }

        let url = `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PRODUCT_UPDATE(productID, product_type)}`;
        // window.open(url, '_parent');

        return url;

        // switch (type) {
        //     case appConfig.PRODUCT_TYPE.SERVICE.CODE:
        //         this.props.history.push(`/services/update/${productID}`);
        //         break;
        //     case appConfig.PRODUCT_TYPE.DIGITAL.CODE:
        //         this.props.history.push(`/digital/update/${productID}`);
        //         break;
        //     case appConfig.PRODUCT_TYPE.EVENT.CODE:
        //         this.props.history.push(`/events/update/${productID}`);
        //         break;
        //     default:
        //         this.props.history.push(`/products/update/${productID}`);
        //         break;
        // }
    };

    handleCopyProduct = (type, productID) => {
        let product_type = 'products';

        switch (type) {
            case appConfig.PRODUCT_TYPE.SERVICE.CODE:
                // window.open(`/services/update/${productID}`, '_blank', 'noopener,noreferrer');
                product_type = 'services';
                break;
            case appConfig.PRODUCT_TYPE.DIGITAL.CODE:
                product_type = 'digital';
                // window.open(`/digital/update/${productID}`, '_blank', 'noopener,noreferrer');
                break;
            case appConfig.PRODUCT_TYPE.EVENT.CODE:
                product_type = 'events';
                // window.open(`/events/update/${productID}`, '_blank', 'noopener,noreferrer');
                break;
            default:
                // window.open(`/products/update/${productID}`, '_blank', 'noopener,noreferrer');
                break;
        }

        let query_ls = { is_copy: true };

        let url = `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PRODUCT_UPDATE(productID, product_type)}&query_ls=${JSON.stringify(
            query_ls
        )}`;
        // window.open(url, '_parent');

        return url;

        // switch (type) {
        //     case appConfig.PRODUCT_TYPE.SERVICE.CODE:
        //         this.props.history.push(`/services/update/${productID}?is_copy=true`);
        //         break;
        //     case appConfig.PRODUCT_TYPE.DIGITAL.CODE:
        //         this.props.history.push(`/digital/update/${productID}?is_copy=true`);
        //         break;
        //     case appConfig.PRODUCT_TYPE.EVENT.CODE:
        //         this.props.history.push(`/events/update/${productID}?is_copy=true`);
        //         break;
        //     default:
        //         this.props.history.push(`/products/update/${productID}?is_copy=true`);
        //         break;
        // }
    };

    hideModalEdit = () => {
        this.setState(
            {
                isShowModalEdit: false,
            },
            () => {
                this.props.history.push('/products');
            }
        );
    };

    openOptionDeleteModal = (selectedID) => {
        this.selectedID = selectedID;

        window.LadiUI.showModal('confirm-product-delete');
    };

    openOptionDeletesModal = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        window.LadiUI.showModal('confirm-product-deletes');
    };

    copyVariantInfo = (product) => {
        this.selectedProduct = product;
        this.props.getVariantInfos(product.product_id);
    };

    // copyProduct = (productID) => {
    //   this.props.copy(productID);
    // };

    exportProduct = (type) => {
        let ids;
        switch (baseHelper.parseInt(type)) {
            case appConfig.EXPORT_PRODUCT.ALL_PRODUCT:
                ids = appConfig.EXPORT_TYPE.ALL;
                break;
            case appConfig.EXPORT_PRODUCT.ON_PAGE:
                const onPageIDs = map(this.props.product.products, (item) => item.product_id);
                ids = onPageIDs;
                break;
            case appConfig.EXPORT_PRODUCT.SELECTED_PRODUCT:
                if (baseHelper.isEmpty(this.state.selectedIDs)) {
                    window.LadiUI.toastCustom('warning', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
                    return;
                }
                ids = this.state.selectedIDs;
                break;
        }

        this.props.exportProduct({ ids });
    };

    exportEvent = (type) => {
        let ids;
        switch (baseHelper.parseInt(type)) {
            case appConfig.EXPORT_EVENT.ALL_EVENT:
                ids = appConfig.EXPORT_TYPE.ALL;
                break;
            case appConfig.EXPORT_EVENT.ON_PAGE:
                const onPageIDs = map(this.props.product.products, (item) => item.product_id);
                ids = onPageIDs;
                break;
            case appConfig.EXPORT_EVENT.SELECTED_EVENT:
                if (baseHelper.isEmpty(this.state.selectedIDs)) {
                    window.LadiUI.toastCustom('warning', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
                    return;
                }
                ids = this.state.selectedIDs;
                break;
        }

        this.props.exportEvent({ ids });
    };

    exportService = (type) => {
        let ids;
        switch (baseHelper.parseInt(type)) {
            case appConfig.EXPORT_SERVICE.ALL_SERVICE:
                ids = appConfig.EXPORT_TYPE.ALL;
                break;
            case appConfig.EXPORT_SERVICE.ON_PAGE:
                const onPageIDs = map(this.props.product.products, (item) => item.product_id);
                ids = onPageIDs;
                break;
            case appConfig.EXPORT_SERVICE.SELECTED_SERVICE:
                if (baseHelper.isEmpty(this.state.selectedIDs)) {
                    window.LadiUI.toastCustom('warning', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
                    return;
                }
                ids = this.state.selectedIDs;
                break;
        }

        this.props.exportService({ ids });
    };

    importProductFile = (event) => {
        const file = event.target.files[0];

        const form = new FormData();
        form.append('file', file, file.name);

        this.props.importProduct(form);

        event.target.value = '';
    };

    importEventFile = (event) => {
        const file = event.target.files[0];

        const form = new FormData();
        form.append('file', file, file.name);

        this.props.importEvent(form);

        event.target.value = '';
    };

    importServiceFile = (event) => {
        const file = event.target.files[0];

        const form = new FormData();
        form.append('file', file, file.name);

        this.props.importService(form);

        event.target.value = '';
    };

    exportProductFile = () => {
        this.setState({
            isShowModalExportProduct: true,
        });
    };
    exportEventFile = () => {
        this.setState({
            isShowModalExportEvent: true,
        });
    };
    exportServiceFile = () => {
        this.setState({
            isShowModalExportService: true,
        });
    };

    eventSelectAction = (action) => {
        switch (action) {
            case 'DELETE':
                this.openOptionDeletesModal();
                break;
            default:
        }
    };
    clearFilter = (callback) => {
        this.setState(
            {
                tag_ids: [],
                product_category_ids: [],
                page_checkout_id: [],
                type: '',
            },
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    };

    filterActionProp = async (conditions) => {
        this.clearFilter();

        // let filter = {
        //     tabSelected: appConfig.ORDER_FILTER_TYPE.SEARCH_ALL,
        //     searchName: ""
        // }

        for await (const condition of conditions) {
            switch (condition.attributeFilter.value) {
                case 'tag_ids':
                    // filter.searchRule = condition.listFilter.map(item => item.value);
                    this.setState({
                        tag_ids: condition.listFilter.map((item) => item.value),
                    });
                    break;
                case 'product_category_ids':
                    this.setState({
                        product_category_ids: condition.listFilter.map((item) => item.value),
                    });
                    break;

                case 'page_checkout_id':
                    this.setState({
                        page_checkout_id: [condition.valueFilter.value],
                    });
                    break;
                case 'product_types':
                    this.setState({
                        type: condition.valueFilter.value,
                    });
                    break;
                default:
                    break;
            }
        }

        // this.setState({
        //     ...filter
        // }, () => {
        //     this.list();
        // })
        this.list();
    };

    getNameProductByStatus = (status) => {
        const matched = find(appConfig.PRODUCT_STATUS, (item) => item.CODE == status) || {};
        if (matched) {
            return matched.NAME;
        }
    };

    getNameProductByType = (type) => {
        const matched = find(appConfig.PRODUCT_TYPE, (item) => item.CODE == type) || {};
        if (matched) {
            return matched.NAME;
        }
    };

    handleClickTag = (item, selectedTags) => {
        this.setState(
            {
                tag_ids: [...this.state.tag_ids, item],
            },
            () => {
                this.list();
            }
        );
    };

    openModalSaveCondition = () => {
        this.setState({
            isShowModalSaveCondition: true,
        });
    };

    parseSearch = (conditions) => {
        let search = {};

        for (const condition of conditions) {
            switch (condition.value) {
                case 'tags':
                    search[condition.value] = {
                        operator: condition.operator.CODE,
                        value: condition.multiSelected.map((item) => item.value),
                    };
                    break;
                case 'product_category':
                    search[condition.value] = {
                        operator: condition.operator.CODE,
                        value: condition.multiSelected.map((item) => item.value),
                    };
                    break;
                // case 'page_checkout_ids':
                //     filter.page_checkout_ids = [condition.valueFilter.value];
                //     break;
                default:
                    break;
            }
        }

        return search;
    };

    setDataCondition = async (data) => {
        let dataConditions = this.state.dataConditions;
        let conditions = dataConditions.conditions || [];

        let index = conditions.findIndex((item) => item.value == data.value);
        if (index > -1) {
            conditions[index] = data;
        } else conditions.push(data);

        dataConditions.conditions = conditions;

        let search = this.parseSearch(conditions);

        this.setState(
            {
                search_v2: search,
                dataConditions: dataConditions,
                tabSelected: appConfig.ORDER_FILTER_TYPE.SEARCH_ALL,
            },
            () => {
                this.list();
            }
        );
    };

    removeCondition = async (index) => {
        let dataConditions = this.state.dataConditions;
        let conditions = dataConditions.conditions || [];

        if (index > -1) conditions.splice(index, 1);
        dataConditions.conditions = conditions;

        let search = this.parseSearch(conditions);

        this.setState(
            {
                search_v2: search,
                dataConditions: dataConditions,
            },
            () => {
                this.list();
            }
        );
    };

    eventSelectActionFilter = (action, data) => {
        switch (action) {
            case 'CHANGE_NAME':
                this.setState({
                    filterAction: data,
                    isShowModalFilterRename: true,
                });
                break;
            case 'CHANGE_COLUMN':
                this.setState({
                    filterAction: data,
                    isShowModalColumnFilter: true,
                });
                break;
            case 'DUPLICATE':
                this.props.filterDuplicate({ filter_id: data.filter_id });
                break;
            case 'DELETE':
                this.setState({
                    filterAction: data,
                });
                window.LadiUI.showModal('confirm-remove-filter');
                break;
            default:
                break;
        }
    };

    onChangeTab = (tabID, event) => {
        if (event) {
            event.stopPropagation();
        }
        this.clearFilter(() => {
            let search_v2 = {};

            let orderTabDefault = Object.values(appConfig.ORDER_FILTER_TYPE);
            let queryStrings = new URLSearchParams(this.props.location.search);
            queryStrings.set('product_tab', tabID);

            let columnSeleted = appConfig.ORDER_COLUMN.filter((item) => item.isSelected);
            // this.props.history;
            this.props.history.replace({ search: queryStrings.toString() });
            switch (tabID) {
                case appConfig.ORDER_FILTER_TYPE.ALL:
                    break;
            }
            let dataConditions = {
                operator: appConfig.OPERATORS.SWITCH_AND_OR.AND,
                conditions: [],
            };
            let isShowFilterCondition = false;

            if (!orderTabDefault.includes(tabID)) {
                let filter = this.state.filterCustomShow.find((item) => item.filter_id == tabID);

                if (filter) {
                    let condition = [];
                    if (filter.config && filter.config.dataConditions) {
                        condition = filter.config.dataConditions.conditions || [];
                        isShowFilterCondition = true;
                    }
                    dataConditions.conditions = condition;
                    search_v2 = this.parseSearch(condition);
                }
            }

            this.setState(
                {
                    dataConditions,
                    search_v2,
                    tabSelected: tabID,
                    isShowFilterCondition,
                },
                () => {
                    this.list();
                }
            );
        });
    };

    selectFilterDropdown = (data) => {
        let { filterCustomShow, filterCustomDropdown } = this.state;
        let index = filterCustomDropdown.findIndex((item) => item.filter_id == data.filter_id);

        if (index == -1) return;

        let filter = filterCustomDropdown.splice(index, 1);

        let filter2 = filterCustomShow[1];
        filterCustomShow[1] = filter[0];
        filterCustomDropdown.push(filter2);
        this.setState(
            {
                filterCustomShow,
                filterCustomDropdown,
            },
            () => {
                this.onChangeTab(filterCustomShow[1].filter_id);
            }
        );
    };

    handleExportDataByType = (productType, condition) => {
        let ids = [];
        switch (baseHelper.parseInt(condition)) {
            case appConfig.EXPORT_PRODUCT.ALL_PRODUCT:
                ids = appConfig.EXPORT_TYPE.ALL;
                break;
            case appConfig.EXPORT_PRODUCT.ON_PAGE:
                const onPageIDs = map(this.props.product.products, (item) => item.product_id);
                ids = onPageIDs;
                break;
            case appConfig.EXPORT_PRODUCT.SELECTED_PRODUCT:
                if (baseHelper.isEmpty(this.state.selectedIDs)) {
                    window.LadiUI.toastCustom('warning', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
                    return;
                }
                ids = this.state.selectedIDs;
                break;
        }

        switch (productType) {
            case appConfig.PRODUCT_TYPE.PHYSICAL.CODE:
                this.props.exportProduct({ ids });
                break;
            case appConfig.PRODUCT_TYPE.EVENT.CODE:
                this.props.exportEvent({ ids });
                break;
            case appConfig.PRODUCT_TYPE.SERVICE.CODE:
                this.props.exportService({ ids });
                break;
            default:
                break;
        }
    };

    handleImportDataByType = (productType) => {
        switch (productType) {
            case appConfig.PRODUCT_TYPE.PHYSICAL.CODE:
                document.getElementById('uploadProductFile').click();
                break;
            case appConfig.PRODUCT_TYPE.EVENT.CODE:
                document.getElementById('uploadEventFile').click();
                break;
            case appConfig.PRODUCT_TYPE.SERVICE.CODE:
                document.getElementById('uploadServiceFile').click();
                break;
            default:
                break;
        }
    };

    render() {
        const { t } = this.props;
        const { totalRecord, totalPage } = this.props.product;
        const {
            activePage,
            selectedLimit,
            searchProductName,
            userInfo,
            conditions,
            conditionProp,
            dataConditions,
            isShowFilterCondition,
            filterCustomShow,
            filterCustomDropdown,
            tabSelected,
        } = this.state;
        let fromItem = 0;
        let toItem = 0;

        if ((this.props.product.products || []).length > 0) {
            fromItem = (conditions.page - 1) * conditions.limit + 1;
            toItem = fromItem + this.props.product.products.length - 1;
        }

        let { product_id: productID, variant_id: variantID } = queryString.parse(this.props.location.search);

        const msgShowItem = ``;

        const isLoadingData = this.props.product.loading && this.props.product.waiting == productTypes.LIST_ALL_PRODUCT;
        const isLoadingInfo =
            (this.props.product.loading && includes([productTypes.SHOW_PRODUCT, productTypes.COPY_PRODUCT], this.props.product.waiting)) ||
            (this.props.variant.loading && this.props.variant.waiting == variantTypes.GET_VARIANT_INFOS);
        const isLoadingDelete =
            this.props.product.loading && includes([productTypes.DELETE_PRODUCT, productTypes.DELETE_PRODUCTS], this.props.product.waiting);
        const isSubmitLoading =
            this.props.product.loading && includes([productTypes.CREATE_PRODUCT, productTypes.UPDATE_PRODUCT], this.props.product.waiting);
        const isLoadingReload = this.props.product.loading && productTypes.RELOAD_PRODUCT == this.props.product.waiting;
        const isLoadingExportProduct = this.props.product.loading && includes([productTypes.EXPORT_PRODUCT], this.props.product.waiting);
        const isLoadingImportProduct = this.props.product.loading && includes([productTypes.IMPORT_PRODUCT], this.props.product.waiting);

        const isLoadingExportEvent = this.props.product.loading && includes([productTypes.EXPORT_EVENT], this.props.product.waiting);
        const isLoadingImportEvent = this.props.product.loading && includes([productTypes.IMPORT_EVENT], this.props.product.waiting);

        const isLoadingExportService = this.props.product.loading && includes([productTypes.EXPORT_SERVICE], this.props.product.waiting);
        const isLoadingImportService = this.props.product.loading && includes([productTypes.IMPORT_SERVICE], this.props.product.waiting);

        const isLoadingRemoveFilter =
            this.props.filterReducer.loading && includes([filterTypes.FILTER_DELETE], this.props.filterReducer.waiting);
        const isLoadingSaveCondition =
            this.props.filterReducer.loading && includes([filterTypes.FILTER_CREATE], this.props.filterReducer.waiting);
        const isLoadingEditCondition =
            this.props.filterReducer.loading && includes([filterTypes.FILTER_UPDATE], this.props.filterReducer.waiting);

        const allTags = this.props.productTagReducer.allTags || [];

        const allCategories = this.props.productCategory.listSelects || [];

        const allStorePages = this.props.storePageReducer.store_pages || [];

        const listAction = [{ value: 'DELETE', name: t('ACTIONS.DELETE') }];

        const isLoadingExportData =
            this.props.product.loading &&
            includes([productTypes.EXPORT_PRODUCT, productTypes.EXPORT_EVENT, productTypes.EXPORT_SERVICE], this.props.product.waiting);
        const isLoadingImportData =
            this.props.product.loading &&
            includes([productTypes.IMPORT_PRODUCT, productTypes.IMPORT_EVENT, productTypes.IMPORT_SERVICE], this.props.product.waiting);

        return (
            <div className='page-content' id='page-content-product'>
                {(isLoadingReload || isLoadingImportProduct || isLoadingImportEvent || isLoadingImportService) && (
                    <LoadingScene blur={true} />
                )}

                <input
                    id='uploadProductFile'
                    type='file'
                    name='file'
                    accept='.xlsx, .xls'
                    onChange={this.importProductFile}
                    onClick={(event) => {
                        event.target.value = null;
                    }}
                    style={{ display: 'none' }}
                />
                <input
                    id='uploadEventFile'
                    type='file'
                    name='file'
                    accept='.xlsx, .xls'
                    onChange={this.importEventFile}
                    onClick={(event) => {
                        event.target.value = null;
                    }}
                    style={{ display: 'none' }}
                />
                <input
                    id='uploadServiceFile'
                    type='file'
                    name='file'
                    accept='.xlsx, .xls'
                    onChange={this.importServiceFile}
                    onClick={(event) => {
                        event.target.value = null;
                    }}
                    style={{ display: 'none' }}
                />

                <div>
                    <div className='header-page'>
                        <div className='heading'>
                            <h3>Sản phẩm</h3>
                            <p>Quản lý các loại sản phẩm bên trong cửa hàng.</p>
                        </div>
                        <div className='action-btn flex items-center'>
                            <div className='ladiui btn-group custom-btn-list-landingpage mr-16'>
                                <div className='ladiui dropdown hide-mt ba-c'>
                                    <button className='dropdown-toggle btn btn-import-export flex items-center' data-toggle='dropdown'>
                                        {/* <img
                                            src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-export.svg'
                                            style={{ marginBottom: '2px', marginRight: '10px' }}
                                        /> */}
                                        <i className='icon-export mr-8' />
                                        {t('PRODUCTS.IMPORT_EXPORT_DATA')}
                                    </button>
                                    <ul className='ladiui dropdown-menu r-0' style={{ width: 'max-content' }}>
                                        <li
                                            onClick={() => {
                                                this.setState({
                                                    isShowModalImportData: true,
                                                });
                                            }}
                                        >
                                            <a className='ladiui dropdown-item'>{'Nhập danh sách sản phẩm'}</a>
                                        </li>
                                        <li
                                            onClick={() => {
                                                this.setState({
                                                    isShowModalExportData: true,
                                                });
                                            }}
                                        >
                                            <a className='ladiui dropdown-item'>{'Xuất danh sách sản phẩm'}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <button
                                type='button'
                                className='ladiui btn btn-primary btn-lg btn-open-modal'
                                data-toggle='modal'
                                onClick={this.openModalCreate}
                            >
                                <img
                                    className='ladiui btn-custom-img'
                                    src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg'
                                    alt=''
                                />
                                <div className='ladiui btn-custom-text '>{t('PRODUCTS.ADD_PRODUCT')}</div>
                            </button>
                        </div>
                    </div>
                    <div style={{ padding: '16px 24px' }}>
                        <div className='block-filter-search'>
                            <div className='ladiui search-group w100'>
                                <input
                                    id='keyword_search'
                                    className='ladiui search-field dropdown-toggle form-control search-width height-40'
                                    name='searchProductName'
                                    placeholder={t('PRODUCTS.SEARCH_PRODUCT')}
                                    aria-expanded='false'
                                    value={searchProductName}
                                    onChange={this.onChangeInput}
                                    onKeyDown={this.onKeyDownInput}
                                />
                                <i className='ladiui icon icon-search' onClick={this.list}></i>
                            </div>
                            <div className='flex items-center'>
                                <Dropdown
                                    data={appConfig.LIST_PRODUCT_TYPE || []}
                                    onSelectItem={(item) => {
                                        this.setState(
                                            {
                                                type: item.CODE,
                                            },
                                            () => {
                                                this.list();
                                            }
                                        );
                                    }}
                                    currentKey={this.state.type}
                                    _key={'CODE'}
                                    _value={'NAME'}
                                    validationName={t('loại sản phẩm')}
                                    placeHolder={t('Lọc theo loại sản phẩm')}
                                    ref={(ref) => (this.paymentRef = ref)}
                                    validations={{ isRequired: true }}
                                    // idBlock='dropdown-confirm-payment'
                                    wrapperClassName='w-auto'
                                />

                                <button
                                    type='button'
                                    className='btn-advanced-filter ml-16'
                                    onClick={() => this.setState({ isShowFilterCondition: !isShowFilterCondition })}
                                >
                                    <div className='ladiui'>Lọc nâng cao</div>
                                    <i className='ldicon-sort' />
                                </button>
                            </div>
                        </div>

                        {isShowFilterCondition && (
                            <div className='block-condition mt-12'>
                                <FilterConditionOrderv2
                                    openModalSaveCondition={this.openModalSaveCondition}
                                    setDataCondition={this.setDataCondition}
                                    removeCondition={this.removeCondition}
                                    conditionProp={conditionProp}
                                    conditionFields={appConfig.PRODUCT_CONDITION_FILEDS}
                                    dataConditions={dataConditions}
                                    // staffs={staffs}
                                    // products={productLists}
                                    allTags={allTags}
                                    allStorePages={allStorePages}
                                />
                            </div>
                        )}
                    </div>
                    {isLoadingData ? (
                        <LoadingTable />
                    ) : (
                        <div className='block-content'>
                            <div className='product-tab'>
                                <ul className='ladiui nav nav-tabs overflow-hide' role='tab-product-all'>
                                    <li className='ladiui nav-item' id='tabAll' role='tab-product-all-item'>
                                        <span
                                            className={`ladiui tab-link ${tabSelected === 'ALL' ? 'active' : ''}`}
                                            data-toggle='tab'
                                            data-tab='tabAll'
                                            onClick={() => this.onChangeTab('ALL')}
                                        >
                                            {'Tất cả'}
                                        </span>
                                    </li>
                                    {filterCustomShow.map((item, index) => {
                                        return (
                                            <li
                                                className='ladiui nav-item item-filter-new'
                                                id={item.filter_id}
                                                role='tab-order-all-item'
                                                key={index}
                                            >
                                                <span
                                                    className={`ladiui tab-link ${tabSelected == item.filter_id ? 'active' : ''}`}
                                                    data-toggle='tab'
                                                    data-tab={`${item.filter_id}`}
                                                    onClick={(event) => this.onChangeTab(item.filter_id, event, item)}
                                                >
                                                    {item.name}
                                                </span>

                                                <div className='ladiui btn-group'>
                                                    <div className='ladiui dropdown'>
                                                        <button
                                                            className={`ladiui btn btn-outline-light dropdown-toggle`}
                                                            data-toggle='dropdown'
                                                        ></button>
                                                        <ul className={`ladiui dropdown-menu filter-actions`}>
                                                            <li
                                                                onClick={() => {
                                                                    this.eventSelectActionFilter('CHANGE_NAME', item);
                                                                }}
                                                            >
                                                                <a className='ladiui dropdown-item'>
                                                                    <i className='ldicon-info' />
                                                                    <span>{t('ACTIONS.CHANGE_NAME')}</span>
                                                                </a>
                                                            </li>
                                                            {/* <li
                              onClick={() => {
                                this.eventSelectActionFilter('CHANGE_COLUMN', item);
                              }}
                            >
                              <a className='ladiui dropdown-item'>
                                <i className='ldicon-info' />
                                <span>{t('ACTIONS.CHANGE_COLUME')}</span>
                              </a>
                            </li> */}
                                                            <li
                                                                onClick={() => {
                                                                    this.eventSelectActionFilter('DUPLICATE', item);
                                                                }}
                                                            >
                                                                <a className='ladiui dropdown-item'>
                                                                    <i className='ldicon-info' />
                                                                    <span>{t('ACTIONS.DUPLICATE')}</span>
                                                                </a>
                                                            </li>
                                                            <li
                                                                onClick={() => {
                                                                    this.eventSelectActionFilter('DELETE', item);
                                                                }}
                                                            >
                                                                <a className='ladiui dropdown-item'>
                                                                    <i className='ldicon-info' />
                                                                    <span>{t('ACTIONS.DELETE')}</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                    {filterCustomDropdown && filterCustomDropdown.length > 0 && (
                                        <Dropdown
                                            data={filterCustomDropdown}
                                            onSelectItem={(item) => {
                                                this.selectFilterDropdown(item);
                                            }}
                                            _key='filter_id'
                                            _value='name'
                                            placeHolder='Chế độ xem khác'
                                            wrapperClassName='dropdown-filter-more'
                                            innerClassName='dropdown-filter-more'
                                        />
                                    )}
                                </ul>
                            </div>
                            <div>
                                <div className='ladi-card ladiui-table-responsive'>
                                    <table className={`ladiui table text-left ${isLoadingInfo ? 'loader' : ''}`}>
                                        <thead style={{ position: 'relative' }}>
                                            <tr className='ladiui table-custom table-vertical'>
                                                <th className='ladiui checkall min-w-30'>
                                                    <input
                                                        id='checkAllItem'
                                                        onClick={this.checkAllItem}
                                                        type='checkbox'
                                                        className='ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                                                    />
                                                </th>
                                                {this.state.selectedIDs.length <= 0 ? (
                                                    <th
                                                        scope='col'
                                                        name='name'
                                                        style={{ width: '500px' }}
                                                        onClick={this.sort}
                                                        className={`text-left sorting ${this.sortBy['name'] == 'DESC' ? 'up' : ''}`}
                                                    >
                                                        {t('PRODUCTS.NAME')}
                                                    </th>
                                                ) : (
                                                    <th scope='col' style={{ width: '500px' }}>
                                                        {t('PRODUCTS.NAME')}
                                                        <LadiDropdownMenu
                                                            labelDropdown={'Chọn hành động'}
                                                            listProp={listAction}
                                                            defaultTextProp={t('ACTIONS.TITLE')}
                                                            hideLabel={true}
                                                            cbProp={this.eventSelectAction}
                                                            classSize='btn-sm '
                                                            classDropdown='w-180 action-record-table'
                                                            style={{ left: '30px' }}
                                                        />
                                                    </th>
                                                )}
                                                <th scope='col'>Cửa hàng</th>
                                                <th scope='col'>{t('COMMON.PRODUCT_TYPE')}</th>
                                                <th scope='col'>{t('COMMON.PRODUCT_STATUS')}</th>
                                                <th scope='col'>{t('COMMON.CREATOR')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {map(this.props.product.products, (product) => {
                                                let tagIds = [];
                                                let tagValues = [];
                                                const tags = product.tags ? product.tags.split(',') : [];
                                                if (tags) {
                                                    map(tags, (_item) => {
                                                        let id = _item.split(':')[0];
                                                        let name = _item.split(':')[1];
                                                        tagIds.push(id);
                                                        tagValues.push(name);
                                                    });
                                                }
                                                return (
                                                    <React.Fragment key={product.product_id}>
                                                        <tr
                                                            key={product.product_id}
                                                            className='ladiui table-custom table-vertical main'
                                                            style={tags.length > 0 ? { borderBottom: 'none' } : {}}
                                                        >
                                                            <td className='min-w-30 ladiui checkall'>
                                                                <input
                                                                    type='checkbox'
                                                                    onClick={(event) => this.checkItem(event, product.product_id)}
                                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                                                                />
                                                            </td>
                                                            <td style={{ maxWidth: '500px', paddingRight: '10px' }}>
                                                                <a
                                                                    className='limit-text-vertical product-name-table'
                                                                    // onClick={() => this.handelProductEdit(product.type, product.product_id)}
                                                                    href={this.handelProductEdit(product.type, product.product_id)}
                                                                    target='_parent'
                                                                >
                                                                    <img
                                                                        className='product_img'
                                                                        src={baseHelper.getImageUrl(product.src)}
                                                                        height={32}
                                                                    />
                                                                    {product.name}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <p style={{ maxWidth: '350px' }} className='limit-text-vertical'>
                                                                    {product.is_show_all_store == 1 ? 'Tất cả' : product.store_name}
                                                                </p>
                                                            </td>
                                                            <td>{this.getNameProductByType(product.type)}</td>
                                                            {/* <td>{this.getNameProductByStatus(product.status)}</td> */}
                                                            <td>
                                                                <div className='product-status-v4'>
                                                                    {product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE ? (
                                                                        <div className='active space-nowrap flex items-center'>
                                                                            Hiển thị
                                                                        </div>
                                                                    ) : (
                                                                        <div className='inactive space-nowrap flex items-center'>
                                                                            Chưa hiển thị
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {baseHelper.getStaffNameById(
                                                                    userInfo.staffs,
                                                                    product.creator_id,
                                                                    userInfo.ladi_uid
                                                                )}
                                                            </td>
                                                            <td className='text-right pd-0'>
                                                                <div className='ladiui btn-group'>
                                                                    <div className='ladiui dropdown hide-mt ba-c'>
                                                                        <button
                                                                            data-toggle='dropdown'
                                                                            className='ladiui-btn-dropdown dropdown-toggle'
                                                                        >
                                                                            <i className='ladiui icon icon-ldp-dot'></i>
                                                                        </button>
                                                                        <ul className='ladiui dropdown-menu r-0 w-180'>
                                                                            <li>
                                                                                <a
                                                                                    className='ladiui dropdown-item'
                                                                                    // onClick={() =>
                                                                                    //     this.handelProductEdit(
                                                                                    //         product.type,
                                                                                    //         product.product_id
                                                                                    //     )
                                                                                    // }
                                                                                    href={this.handelProductEdit(
                                                                                        product.type,
                                                                                        product.product_id
                                                                                    )}
                                                                                    target='_parent'
                                                                                >
                                                                                    <i className='new-ldicon-edit' />
                                                                                    <span> {t('ACTIONS.EDIT')} </span>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    className='ladiui dropdown-item'
                                                                                    href={this.handleCopyProduct(
                                                                                        product.type,
                                                                                        product.product_id
                                                                                    )}
                                                                                    target='_parent'
                                                                                >
                                                                                    <i className='new-ldicon-copy ladiui icon-new' />
                                                                                    <span>{t('PRODUCTS.COPY_PRODUCT_PHYSICAL')}</span>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    className='ladiui dropdown-item'
                                                                                    onClick={() => this.copyVariantInfo(product)}
                                                                                >
                                                                                    <i className='new-ldicon-copy ladiui icon-new' />
                                                                                    <span>{t('PRODUCTS.GET_INFO')}</span>
                                                                                </a>
                                                                            </li>
                                                                            {product.url_published && (
                                                                                <li>
                                                                                    <a
                                                                                        className='ladiui dropdown-item'
                                                                                        target='_blank'
                                                                                        onClick={() => {
                                                                                            window.open(product.url_published);
                                                                                        }}
                                                                                    >
                                                                                        <i className='ladiui icon-new new-ldicon-website-2' />
                                                                                        <span>{t('COMMON.PREVIEW')}</span>
                                                                                    </a>
                                                                                </li>
                                                                            )}
                                                                            <li>
                                                                                <a
                                                                                    className='ladiui dropdown-item'
                                                                                    onClick={() =>
                                                                                        this.openOptionDeleteModal(product.product_id)
                                                                                    }
                                                                                >
                                                                                    <i className='ladiui icon-new new-ldicon-delete' />
                                                                                    <span className='ladiui text-danger'>
                                                                                        {t('ACTIONS.DELETE')}
                                                                                    </span>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {tags && tags.length > 0 && (
                                                            <tr className='ladiui table-vertical main custom-tag'>
                                                                <td></td>
                                                                <td colSpan={10}>
                                                                    {tags.length > 0 && (
                                                                        <ListTags
                                                                            tagValues={tagIds}
                                                                            tagColors={[]}
                                                                            tagNames={tagValues}
                                                                            handleClickTagItem={this.handleClickTag}
                                                                        />
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                            {totalRecord <= 0 && (
                                                <tr className='text-center'>
                                                    {/* <td colSpan='100%'>{t('NO_DATA')}</td> */}
                                                    <td colSpan='100%'>
                                                        <div className='block-nodata-search-product'>
                                                            <i className='icon-search'></i>
                                                            <h3>Không tìm thấy kết quả nào</h3>
                                                            <p>
                                                                Thay đổi nội dung tìm kiếm hoặc gỡ bỏ bộ lọc hiện tại để tìm kiếm kết quả
                                                                bạn cần
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {totalRecord > 0 && (
                                    <LadiPagination
                                        conditionsProp={conditions}
                                        listName={t('PAGINATION.ITEMS')}
                                        message={''}
                                        items={this.props.product.products}
                                        total={totalRecord}
                                        limitAction={this.onChangeLimit}
                                        pageAction={this.onPageChange}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>

                {this.state.isShowModalSaveCondition && (
                    <ModalSaveCondition
                        visible={this.state.isShowModalSaveCondition}
                        isLoading={isLoadingSaveCondition}
                        hideModalSaveCondition={() => {
                            this.setState({
                                isShowModalSaveCondition: false,
                            });
                        }}
                        selectedType='PRODUCT'
                        dataConditions={dataConditions}
                        filters={this.state.filterCustom}
                    />
                )}

                {this.state.isShowModalFilterRename && (
                    <ModalRename
                        visible={this.state.isShowModalFilterRename}
                        hideModal={() => {
                            this.setState({
                                isShowModalFilterRename: false,
                            });
                        }}
                        dataConditions={this.state.filterAction}
                        isLoading={isLoadingEditCondition}
                    />
                )}

                {
                    this.state.isShowModalCreate && (
                        <ModalSelectProduct
                            showModal={this.state.isShowModalCreate}
                            loading={isSubmitLoading}
                            userEmail={userInfo.email ? userInfo.email : null}
                            onCancel={this.hideModalCreate}
                        />
                    )
                    // <ModalCreate
                    //     visible={this.state.isShowModalCreate}
                    //     loading={isSubmitLoading}
                    //     onCancel={this.hideModalCreate}
                    //     onSubmit={(product) => this.props.create(product)}
                    //     currentProductInfo={this.state.currentProductInfo}
                    // />
                }

                {this.state.isShowModalEdit && (
                    <ModalEdit
                        currentProductInfo={this.state.currentProductInfo}
                        visible={this.state.isShowModalEdit}
                        loading={isSubmitLoading}
                        onCancel={this.hideModalEdit}
                        onSubmit={(product) => this.props.update(product)}
                        openVariantID={variantID}
                    />
                )}

                {this.state.isShowModalExportProduct && (
                    <ModalExportProduct
                        visible={this.state.isShowModalExportProduct}
                        isLoadingExportProduct={isLoadingExportProduct}
                        onExportProduct={this.exportProduct}
                        onCancel={() => {
                            this.setState({
                                isShowModalExportProduct: false,
                            });
                        }}
                    />
                )}

                {this.state.isShowModalExportEvent && (
                    <ModalExportEvent
                        visible={this.state.isShowModalExportEvent}
                        isLoadingExportEvent={isLoadingExportEvent}
                        onExportEvent={this.exportEvent}
                        onCancel={() => {
                            this.setState({
                                isShowModalExportEvent: false,
                            });
                        }}
                    />
                )}

                {this.state.isShowModalExportService && (
                    <ModalExportService
                        visible={this.state.isShowModalExportService}
                        isLoadingExportEvent={isLoadingExportService}
                        onExportService={this.exportService}
                        onCancel={() => {
                            this.setState({
                                isShowModalExportService: false,
                            });
                        }}
                    />
                )}

                {this.state.isShowModalExportData && (
                    <ModalExportProductByType
                        visible={this.state.isShowModalExportData}
                        onCancel={() => {
                            this.setState({
                                isShowModalExportData: false,
                            });
                        }}
                        isLoadingExportData={isLoadingExportData}
                        onExportData={this.handleExportDataByType}
                    />
                )}

                {this.state.isShowModalImportData && (
                    <ModalImportProductByType
                        visible={this.state.isShowModalImportData}
                        onCancel={() => {
                            this.setState({
                                isShowModalImportData: false,
                            });
                        }}
                        isLoadingExportData={isLoadingImportData}
                        onImportData={this.handleImportDataByType}
                    />
                )}

                <ConfirmModal
                    id='confirm-product-delete'
                    title={t('PRODUCTS.MSG_DELETE_PRODUCT_TITLE')}
                    content={t('PRODUCTS.MSG_DELETE_PRODUCT_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.delete(this.selectedID)}
                    isLoading={isLoadingDelete}
                />

                <ConfirmModal
                    id='confirm-remove-filter'
                    title={t('CONDITIONS_FILTER.DELETE_TITLE')}
                    content={t('CONDITIONS_FILTER.DELETE_CONTENT')}
                    cancelText={t('ACTIONS.REMOVE')}
                    okText={t('ACTIONS.OK')}
                    onOk={() => {
                        this.props.filterDelete({
                            filter_id: this.state.filterAction.filter_id,
                        });
                    }}
                    isLoading={isLoadingRemoveFilter}
                />

                <ConfirmModal
                    id='confirm-product-deletes'
                    title={t('PRODUCTS.MSG_DELETE_PRODUCT_TITLE')}
                    content={t('PRODUCTS.MSG_DELETE_PRODUCTS_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.deletes(this.state.selectedIDs)}
                    isLoading={isLoadingDelete}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(productActions.list(data)),
        listAllProducts: (data) => dispatch(productActions.listAllProducts(data)),
        deletes: (productIDs) => dispatch(productActions.deletes(productIDs)),
        delete: (productID) => dispatch(productActions.delete(productID)),
        show: (productID) => dispatch(productActions.show(productID)),
        reload: (productID) => dispatch(productActions.reload(productID)),
        copy: (productID) => dispatch(productActions.copy(productID)),
        create: (product) => dispatch(productActions.create(product)),
        update: (product) => dispatch(productActions.update(product)),
        getVariantInfos: (productID) => dispatch(variantActions.getVariantInfos(productID)),
        listAllTags: () => dispatch(productTagActions.listAll()),
        importProduct: (form) => dispatch(productActions.importProduct(form)),
        exportProduct: (data) => dispatch(productActions.exportProduct(data)),
        importEvent: (data) => dispatch(productActions.importEvent(data)),
        exportEvent: (data) => dispatch(productActions.exportEvent(data)),
        importService: (form) => dispatch(productActions.importService(form)),
        exportService: (data) => dispatch(productActions.exportService(data)),
        listAllCategories: (data) => dispatch(productCategoryActions.listSelectCategory(data)),
        listStores: (data) => dispatch(storePageActions.storePageList(data)),
        filterList: (data) => dispatch(filterActions.list(data)),
        filterDelete: (data) => dispatch(filterActions.deletes(data)),
        filterDuplicate: (data) => dispatch(filterActions.duplicate(data)),
    };
};

const mapStateToProps = (state) => ({
    product: { ...state.product },
    productCategory: { ...state.productCategory },
    variant: { ...state.variant },
    productTagReducer: { ...state.productTag },
    storeReducer: { ...state.store },
    storePageReducer: { ...state.storePage },
    filterReducer: { ...state.filterReducer },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Product));
