import React from 'react';

import { connect } from 'react-redux';

import { map, find } from 'lodash';
import appConfig from '../../config/app';
import Pagination from 'react-js-pagination';
import baseHelper from '../../helpers/BaseHelper';

import * as types from '../../redux/futures/checkout/types';
import checkoutActions from '../../redux/futures/checkout/actions';

import LoadingSence from '../../components/LoadingScene';
import Image from '../../components/Image';
import PanelCustomer from '../orders/components/OrderCustomer';
import PanelShippingAddress from '../orders/components/ShippingAddress';
import { withTranslation } from 'react-i18next';
import Input from '../../components/Input';
import OrderDefined from '../orders/OrderDefined';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

class CheckoutEdit extends React.Component {
    constructor(props) {
        super(props);

        this.moment = baseHelper.getMoment();

        this.state = {
            customer_note: '',
        };

        this.sortBy = {};
    }

    componentDidMount() {
        this.props.show(this.props.match.params['checkoutID']);
    }

    componentDidUpdate() {
        window.LadiUI.init();
        window.LadiUI.customInit();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.checkoutReducer.action != nextProps.checkoutReducer.action) {
            if (nextProps.checkoutReducer.action === types.SHOW_CHECKOUT) {
                if (nextProps.checkoutReducer.status) {
                    this.setState({
                        customer_note: nextProps.checkoutReducer.checkout.customer_note,
                    });
                } else {
                    this.props.history.push('/checkouts');
                }
            }

            if (nextProps.checkoutReducer.action === types.UPDATE_CHECKOUT) {
                if (nextProps.checkoutReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.checkoutReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.checkoutReducer.message, 'OK');
                }
            }

            if (nextProps.checkoutReducer.action === types.SEND_NOTIFICATION_CHECKOUT) {
                if (nextProps.checkoutReducer.status) {
                    this.props.reload(this.props.checkoutReducer.checkout.checkout_id);
                    window.LadiUI.toastCustom('success', '', nextProps.checkoutReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.checkoutReducer.message, 'OK');
                }
            }
        }
    }

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    getStatus = (status) => {
        const found = find(appConfig.CHECKOUT.STATUS, (item) => item.CODE == status);
        if (found) {
            return found.NAME;
        }
    };

    getMoreInfo = () => {
        const { t } = this.props;
        const { checkout = {} } = this.props.checkoutReducer;

        const copy = (text) => {
            baseHelper.copyToClipboard(text);
        };

        return (
            <div className='more-info order-customer block-tracking-info pr-0'>
                <div className='item-tracking' data-tooltip={checkout.ip} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(checkout.ip)} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.IP')}:</b>
                    </span>
                    <span className='show-content'>{checkout.ip}</span>
                </div>
                <div className='item-tracking' data-tooltip={checkout.url_page} data-tooltip-position='bottom'>
                    <span>
                        <i onClick={() => copy(checkout.url_page)} className='ladi-icon icon-copy' />
                        <b>{t('ORDERS.URL_PAGE')}:</b>
                    </span>
                    <a target='_blank' href={checkout.url_page}>
                        <span className='show-content'>{checkout.url_page}</span>
                    </a>
                </div>
            </div>
        );
    };

    update = () => {
        const checkoutID = this.props.match.params['checkoutID'];
        this.props.update({
            checkout_id: checkoutID,
            customer_note: this.state.customer_note,
        });
    };

    /****************************************************************/
    render() {
        const { t } = this.props;
        const isLoadingData =
            this.props.checkoutReducer.loading &&
            [types.SHOW_CHECKOUT, types.SEND_NOTIFICATION_CHECKOUT].includes(this.props.checkoutReducer.waiting);
        const isLoadingUpdate = this.props.checkoutReducer.loading && [types.UPDATE_CHECKOUT].includes(this.props.checkoutReducer.waiting);

        const { checkout = {} } = this.props.checkoutReducer;
        const { details: details = [] } = checkout;
        const customer = {
            customer_id: checkout.customer_id,
            email: checkout.customer_email,
            phone: checkout.customer_phone,
            first_name: checkout.customer_first_name,
            last_name: checkout.customer_last_name,
            total_order: checkout.total_order,
        };
        const address = {
            first_name: checkout.customer_first_name,
            last_name: checkout.customer_last_name,
            phone: checkout.customer_phone,
            address: checkout.customer_address,
            country_code: checkout.country_code,
            country_name: checkout.country_name,
            state_id: checkout.state_id,
            state_name: checkout.state_name,
            district_id: checkout.district_id,
            district_name: checkout.district_name,
            ward_id: checkout.ward_id,
            ward_name: checkout.ward_name,
            postal_code: checkout.postal_code,
        };

        let urlEmail = `${baseHelper.domainToUrl(this.props.storeReducer.userInfo.default_domain || 'checkout.ladi.sale')}/checkout/${checkout.checkout_token
            }`;

        if (checkout.url_page) {
            let urlPage = new URL(checkout.url_page).searchParams;
            const checkout_config_id = urlPage.get('checkout_config_id');

            if (checkout_config_id) {
                urlEmail += `?checkout_config_id=${checkout_config_id}`;
            }
        }

        return (
            <div className=''>
                {isLoadingData && <LoadingSence blur={true} />}
                <div className='ladiui header-o'>
                    <div className='ladiui header'>
                        <div className='ladiui order-breadcrumbs'>
                            <span
                                className='back-btn'
                                onClick={() => {
                                    if (this.props.history.length > 1) this.props.history.goBack();
                                    else this.props.history.push('/orders?tab=2');
                                }}
                            >
                                {t('CHECKOUTS.TITLE')}
                            </span>
                            <i className='icon ldicon-chevron-right' />
                            <span className='ladiui label-16'>
                                {`${t('ORDERS.SLIDEBAR_TITLE')} #${baseHelper.getText(checkout.checkout_id)}`}
                            </span>
                        </div>
                        <div className='order-head-actions'>
                            <button
                                className={`ladiui btn btn-md btn-primary ${isLoadingUpdate && 'loader'}`}
                                disabled={isLoadingUpdate}
                                onClick={this.update}
                            >
                                {t('ORDERS.SAVE')}
                            </button>
                        </div>
                    </div>
                </div>

                <div className='checkout-content'>
                    <div className='ladiui content-max-width'>
                        <div className='ladiui content-main-title flex-row'>
                            <div className='ladiui flex-row'>
                                <div className='ladiui main-title mr-12'>{`${t('CHECKOUTS.TITLE')} #${baseHelper.getText(
                                    checkout.checkout_id
                                )}`}</div>
                            </div>
                        </div>
                        <div>
                            {`${t('ORDERS.CREATED_AT')} ${baseHelper.formatDateToStr(
                                checkout.created_at,
                                appConfig.DEFAULT_FORMAT_DATE_TIME
                            )}`}
                        </div>

                        {!checkout && <div className='email-confirm' />}

                        {checkout.email_status == appConfig.CHECKOUT.EMAIL_STATUS.NOT_SEND.CODE && (
                            <div className='mt-20 ladiui container-place'>
                                <div className='display-flex-center'>
                                    <i className='new-ldicon-fill-cart mr-8' />
                                    <span className='ladiui text-14'>{t('CHECKOUTS.SEND_EMAIL_CONFIRM')}</span>
                                </div>
                                <div className='display-flex-center mt-16 gap-16'>
                                    {/* <Input
                                        value={checkout && checkout.checkout_url ? checkout.checkout_url : urlEmail}
                                        disabled={true}
                                        className='checkout-url'
                                    /> */}
                                    <div className='checkout-edit-url'>
                                        {checkout && checkout.checkout_url ? checkout.checkout_url : urlEmail}
                                        <i
                                            className='ladiui icon-new new-ldicon-copy icon-20'
                                            onClick={() =>
                                                baseHelper.copyToClipboard(
                                                    checkout && checkout.checkout_url ? checkout.checkout_url : urlEmail
                                                )
                                            }
                                        />
                                    </div>
                                    {checkout.customer_email && (
                                        <button
                                            className='ladiui btn btn-outline-primary btn-md'
                                            onClick={() => this.props.sendNotification(checkout.checkout_id)}
                                        >
                                            {t('CHECKOUTS.BTN_SEND_EMAIL')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}

                        {checkout.email_status == appConfig.CHECKOUT.EMAIL_STATUS.SENT.CODE && (
                            <div className='mt-20 ladiui container-place'>
                                <div className='display-flex-center'>
                                    {/* <i className='new-ldicon-circlecheck-fill mr-8' /> */}
                                    <span className='ladiui text-14 '>{t('CHECKOUTS.SEND_EMAIL_SUCCESS')}</span>
                                </div>
                                <div className='checkout-edit-url'>
                                    {urlEmail}
                                    <i
                                        className='ladiui icon-new new-ldicon-copy icon-20'
                                        onClick={() => baseHelper.copyToClipboard(urlEmail)}
                                    />
                                </div>
                                {/* <Input value={urlEmail} disabled={true} className='mt-16' /> */}
                            </div>
                        )}
                        <div className='checkout-details mt-20'>
                            <div className='ladiui product-list-payment'>
                                <div className='ladiui container-place product-list'>
                                    <div className='order-product-title pb-12'>
                                        <label className='ladiui label-18'>{t('PRODUCTS.PRODUCT_INFO')}</label>
                                    </div>

                                    <div className='variant-selected-list'>
                                        <div className='variant-selected-item display-flex-center'>
                                            <div className='variant-image-name-option ladiui heading-label-2'>{t('MENU.PRODUCTS')}</div>
                                            <div className='variant-quatity ladiui heading-label-2'> {t('COMMON.QUANTITY')} </div>
                                            <div className='variant-price-discount ladiui heading-label-2'> {t('COMMON.PRICE')} </div>
                                            <div className='variant-total ladiui heading-label-2'> {t('COMMON.TOTAL_AMOUNT')} </div>
                                        </div>
                                        {details.map((item, index) => {
                                            let imageSrc = baseHelper.getImageUrl(item.src);
                                            let optionNames = item.options ? item.options.map((option) => option.option_value_value) : [];
                                            let optionName = optionNames.join(', ');
                                            let name = item.product_name;
                                            const hasDiscount = item.discount_value > 0 ? true : false;
                                            let lastPrice = item.price;
                                            if (hasDiscount) {
                                                lastPrice -= item.discount_fee;
                                            }

                                            return (
                                                <div className='variant-selected-item display-flex-center boder-top' key={index}>
                                                    <div className='variant-image-name-option display-flex-center'>
                                                        <img className='variant-image mr-24' src={imageSrc} alt='variant-selected-image' />
                                                        <div className='variant-name-option'>
                                                            <div className='variant-name ladiui label-16'>
                                                                {item.product_id ? (
                                                                    <a
                                                                        href={baseHelper.getUrlVariant(item)}
                                                                        className='item-title'
                                                                        target='_blank'
                                                                    >
                                                                        {name}
                                                                    </a>
                                                                ) : (
                                                                    <span className='item-title'>{name}</span>
                                                                )}
                                                            </div>
                                                            <div className='option ladiuid text-14 mt-4'>{optionName}</div>
                                                        </div>
                                                    </div>
                                                    <div className='variant-quatity'>
                                                        <span>{item.quantity}</span>
                                                    </div>
                                                    <div className='variant-price-discount'>
                                                        <div>
                                                            <span className={`price-option `}>
                                                                {baseHelper.formatMoneyPostFix(
                                                                    lastPrice,
                                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                                )}
                                                            </span>
                                                            {hasDiscount && (
                                                                <div className='price-option price-before-discount'>
                                                                    {baseHelper.formatMoneyPostFix(
                                                                        item.price,
                                                                        this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='variant-total ladiui text-14'>
                                                        {baseHelper.formatMoneyPostFix(
                                                            item.total,
                                                            this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className='ladiui container-place order-payment payments mt-20'>
                                    <div className='payment-title'>
                                        <label className='ladiui label-18 '>{t('ORDERS.PAYMENT')}</label>
                                    </div>
                                    <div className='payment-info'>
                                        <div className='paymnet-item ladiui text-14'>
                                            <span className=''>{t('ORDERS.TOTAL_AMOUNT')}</span>{' '}
                                            <span>
                                                {baseHelper.formatMoneyPostFix(
                                                    checkout.sub_total,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                        <div className='paymnet-item ladiui text-14'>
                                            <span className=''>{t('ORDERS.DISCOUNT')}</span>{' '}
                                            <span>{checkout.discount_note || checkout.discount_code}</span>
                                        </div>
                                        {checkout.package_discount_value > 0 && (
                                            <div className='paymnet-item ladiui text-14'>
                                                <span className=''>{checkout.package_discount_note}</span>{' '}
                                                <span>
                                                    {baseHelper.formatMoneyPostFix(
                                                        checkout.package_discount_price,
                                                        this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                                </span>
                                            </div>
                                        )}

                                        <div className='paymnet-item ladiui text-14'>
                                            <span className=''>{t('ORDERS.TOTAL_ORDER')}</span>
                                            <span>
                                                {baseHelper.formatMoneyPostFix(
                                                    checkout.total,
                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='customer-tracking'>
                                <div className='ladiui container-place customer'>
                                    <PanelCustomer
                                        mode={appConfig.FORM_MODE.VIEW}
                                        orderID={this.props.match.params['orderID']}
                                        orderCustomer={customer}
                                        checkout={checkout}
                                    />
                                </div>
                                <div className='order-tracking mt-20 ladiui container-place'>
                                    <div className='more-info block-tracking-info'>
                                        {Object.values(OrderDefined.ORDER_TRACKING_LIST).map((item, index) => {
                                            if (!checkout[item.field]) {
                                                return (
                                                    <div key={index} className='item-tracking'>
                                                        <span className='ladiui text-14'>
                                                            <i className='ladi-icon icon-copy' />
                                                            <b>{item.name}:</b>
                                                        </span>
                                                    </div>
                                                );
                                            }
                                            if (checkout[item.field]) {
                                                return (
                                                    <div
                                                        key={index}
                                                        className='item-tracking'
                                                        data-tooltip={checkout[item.field] ? checkout[item.field] : ''}
                                                        data-tooltip-position='top'
                                                    >
                                                        <span className='ladiui text-14'>
                                                            <i
                                                                onClick={() =>
                                                                    baseHelper.copyToClipboard(
                                                                        checkout[item.field] ? checkout[item.field] : ''
                                                                    )
                                                                }
                                                                className='ladi-icon icon-copy'
                                                            />
                                                            <b>{item.name}:</b>
                                                        </span>
                                                        {checkout[item.field] && (
                                                            <span className='show-content'>{checkout[item.field]}</span>
                                                        )}
                                                    </div>
                                                );
                                            } else
                                                return (
                                                    <div key={index} className='item-tracking'>
                                                        <span className='ladiui text-14'>
                                                            <i
                                                                onClick={() =>
                                                                    baseHelper.copyToClipboard(
                                                                        checkout[item.field] ? checkout[item.field] : ''
                                                                    )
                                                                }
                                                                className='ladi-icon icon-copy'
                                                            />
                                                            <b>{item.name}:</b>
                                                        </span>
                                                    </div>
                                                );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        show: (checkoutID) => dispatch(checkoutActions.show(checkoutID)),
        reload: (checkoutID) => dispatch(checkoutActions.reload(checkoutID)),
        sendNotification: (checkoutID) => dispatch(checkoutActions.sendNotification(checkoutID)),
        update: (data) => dispatch(checkoutActions.update(data)),
    };
};

const mapStateToProps = (state) => ({
    checkoutReducer: { ...state.checkout },
    storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CheckoutEdit));
