import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { I18nContext, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import produce from 'immer';
import Input from '../../../../components/Input';
import { map, find } from 'lodash';

// import checkoutConfigActions from "../../../../redux/futures/checkout_config/actions";
// import * as checkoutConfigTypes from "../../../../redux/futures/checkout_config/types";

import pageCheckoutActions from '../../../../redux/futures/page_checkout/actions';
import * as pageCheckoutTypes from '../../../../redux/futures/page_checkout/types';

import domainActions from '../../../../redux/futures/domain/actions';
import * as domainTypes from '../../../../redux/futures/domain/types';

import fileActions from '../../../../redux/futures/file/actions';
import * as fileTypes from '../../../../redux/futures/file/types';

import SelectOption from '../../../../components/SelectOption';
import PanelProductSeoSocialSetting from '../../../../components/PanelProductSeoSocialSetting';

import baseHelper from '../../../../helpers/BaseHelper';

import appConfig from '../../../../config/app';
import config from '../../../../config/config';
import ImageSharingSeoSocialSetting from '../../../../components/ImageSharingSeoSocialSetting';
import UploadImageCustom from '../../../../components/UploadImageCustom';

const CDN = 'https://w.ladicdn.com/';

const PanelSettingDomainCourse = forwardRef((props, ref) => {
    let { isShow, currentDataDomain, selectedLogo, urlPublished, selectedPath, handlePreviewPageCourse, currentDataSeo, currentPagePublicType, currentLabelAccountConfigs, currentFreeCourseLabel, currentLadiShareStore, currentAffiliateStatus, handleActiveAffiliatePageCourse } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const checkoutConfigReducer = useSelector((state) => state.checkoutConfigReducer);
    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);
    const domainReducer = useSelector((state) => state.domainReducer);

    const fileReducer = useSelector((state) => state.file);

    const courseReducer = useSelector((state) => state.course);

    const inputsRef = new Set();

    const productSeoSocialRef = React.createRef();

    const [pageCheckout, setPageCheckout] = useState({
        checkout_config_id: null,
        domain: '',
    });

    const [logo, setLogo] = useState('');
    const [urlFavicon, setUrlFavicon] = useState(props?.currentDataSeo?.favicon || '');

    const [urlDefault, setUrlDefault] = useState('');
    const [pagePublicType, setPagePublicType] = useState('PUBLIC');
    const [freeAccountLabel, setFreeAccountLabel] = useState('Miễn phí');
    const [paidAccountLabel, setPaidAccountLabel] = useState('Trả phí');
    const [freeCourseLabel, setFreeCourseLabel] = useState('Học miễn phí');

    const [statusAffiliate, setStatusAffiliate] = useState(false);
    const [ladiShareStore, setLadiShareStore] = useState(null);

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {
                checkout_config_id: pageCheckout.checkout_config_id,
                // domain: pageCheckout.domain,
                seo: productSeoSocialRef?.current ? { ...productSeoSocialRef.current.getData(), favicon: urlFavicon } : [],
                logo: logo,
                page_public_type: pagePublicType,
                label_account_configs: {
                    free_label: freeAccountLabel,
                    paid_label: paidAccountLabel,
                },
                free_course_label: freeCourseLabel,
                affiliate_status: statusAffiliate,
            };
        },
    }));

    useEffect(() => {
        if (currentDataDomain) {
            setPageCheckout({
                checkout_config_id: currentDataDomain.checkout_config_id || null,
                domain: currentDataDomain.domain,
            });
        }
    }, [currentDataDomain]);

    useEffect(() => {
        if (currentPagePublicType) {
            setPagePublicType(
                currentPagePublicType || 'PUBLIC'
            );
        }
    }, [currentPagePublicType]);

    useEffect(() => {
        if (currentFreeCourseLabel) {
            setFreeCourseLabel(
                currentFreeCourseLabel || 'Học miễn phí'
            );
        }
    }, [currentFreeCourseLabel]);

    useEffect(() => {
        if (currentLabelAccountConfigs) {
            setFreeAccountLabel(currentLabelAccountConfigs?.free_label || 'Miễn phí');
            setPaidAccountLabel(currentLabelAccountConfigs?.paid_label || 'Trả phí');
        }
    }, [currentLabelAccountConfigs]);

    useEffect(() => {
        if (currentDataSeo?.favicon) {
            setUrlFavicon(currentDataSeo?.favicon);
        }
    }, [currentDataSeo]);

    useEffect(() => {
        if (currentLadiShareStore?._id) {
            setStatusAffiliate(currentAffiliateStatus || false);
        } else {
            setStatusAffiliate(false);
        }
    }, [currentAffiliateStatus]);

    useEffect(() => {
        if (currentLadiShareStore?._id) {
            setLadiShareStore(currentAffiliateStatus);
        }
    }, [currentLadiShareStore]);

    useEffect(() => {
        setUrlDefault(`https://${appConfig.DOMAIN_COURSE_DEFAULT}/${selectedPath}`);
    }, [selectedPath]);

    useEffect(() => {
        if (selectedLogo && selectedLogo != '') {
            setLogo(selectedLogo);
        }
    }, [selectedLogo]);

    const handleSubmitData = () => {
        props.handleSubmitData();
    };

    useEffect(() => {
        let payload = {
            search: {
                type: 'embed',
                embed_platform: 'Ladipage',
            },
        };
        dispatch(pageCheckoutActions.list(payload));
        dispatch(
            domainActions.list({
                types: 'PAGE_COURSE',
                is_verified: 1,
            })
        );
    }, []);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_CART_LOGO) {
            if (fileReducer.status) {
                setLogo(fileReducer.cart_logo_url);
            }
        }
        if (fileReducer.action == fileTypes.UPLOAD_FAVICON_URL) {
            if (fileReducer.status) {
                setUrlFavicon(fileReducer.favicon_url);
            }
        }
    }, [fileReducer]);

    useEffect(() => {
        if (pageCheckoutReducer.page_checkouts && pageCheckoutReducer.page_checkouts.length > 0) {
            // let defaultPageCheckout = pageCheckoutReducer.page_checkouts.filter((item) => item.is_default == 1);

            let defaultPageCheckout = find(pageCheckoutReducer.page_checkouts, (item) => item.is_default == appConfig.STATUS.ACTIVE);

            if (defaultPageCheckout && !pageCheckout.checkout_config_id) {
                setPageCheckout({
                    ...pageCheckout,
                    checkout_config_id: defaultPageCheckout.page_checkout_id,
                });
            }
        }
    }, [pageCheckoutReducer]);

    const handelChangeConfig = (checkoutConfigId) => {
        setPageCheckout({
            ...pageCheckout,
            checkout_config_id: checkoutConfigId,
        });
    };

    const handelChangeDomain = (domainData) => {
        setPageCheckout({
            ...pageCheckout,
            domain: domainData.domain,
        });
    };

    const fileChangedHandlerCartLogo = (event) => {
        if (event.target.files.length > 0) {
            const uploadFile = event.target.files[0];
            const form = baseHelper.getFormDataUpload([uploadFile]);
            if (form) {
                dispatch(fileActions.uploadCartLogo(form));
            }
        }
    };

    const removeCartLogo = () => {
        setLogo('');
    };

    const removeCartFavicon = () => {
        setUrlFavicon('');
    };

    const handleActionPreviewPageCourse = () => {
        handlePreviewPageCourse();
    };

    const fileChangedHandlerCartFavicon = (event) => {
        if (event.target.files.length > 0) {
            const uploadFile = event.target.files[0];
            const form = baseHelper.getFormDataUpload([uploadFile]);
            if (form) {
                dispatch(fileActions.uploadFavicon(form));
            }
        }
    };

    const handleDeleteLogo = () => {
        setLogo('');
    };

    const handleDeleteFavicon = () => {
        setUrlFavicon('');
    };

    const handleSetListImageLogo = (urlImage) => {
        setLogo(urlImage);
    };

    const handleSetListImageFavicon = (urlImage) => {
        setUrlFavicon(urlImage);
    };

    const handleInitAffiliate = () => {
        handleActiveAffiliatePageCourse();
    };

    const handleActiveAffiliate = () => {
        setStatusAffiliate(true);
    };

    const handleInActiveAffiliate = () => {
        setStatusAffiliate(false);
    };

    return (
        <div className='page-setting' style={isShow ? { display: 'block' } : { display: 'none' }}>
            <div className='ls-flex ls-flex-col ls-items-start ls-gap-24'>
                <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Logo
                        </div>
                        <div className='ls-setting-row-description'>
                            Kích thước khuyến nghị 400x100 px
                            Định dạng .png, .jpg, .svg)
                        </div>
                    </div>
                    <div className='ls-setting-row-right'>
                        <div className='ls-image-setting-row'>
                            <div className='' style={{ width: 'auto', height: 'auto' }}>
                                <div className='block-content-item ls-p-0 ls-shadow-none'>
                                    <div className='block-list-images ls-p-0 ls-shadow-none'>
                                        <UploadImageCustom
                                            handleChangeImageUrl={fileChangedHandlerCartLogo}
                                            listImage={logo != '' ? [logo] : []}
                                            handleDeleteImage={handleDeleteLogo}
                                            handleSetImage={(items) => { }}
                                            isEditProduct={true}
                                            productID={null}
                                            handleSetListImageProducts={handleSetListImageLogo}
                                            imageWidth={240}
                                            imageHeight={60}
                                            uploadId='upload-logo'
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className='ls-preview-store-logo'>
                                {
                                    logo ? <img
                                        className='ls-img-chooser'
                                        src={logo ? `${CDN}${logo}` : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'}
                                        alt=''
                                    /> : <i className='new-ldicon-photo ls-size-16'></i>
                                }

                                <div className='ls-img-chooser-overlay'>
                                    <div className='ladiui btn-group'>
                                        <div className='ladiui dropdown'>
                                            <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                <i className='ladiui icon icon-ldp-pen-edit'></i>
                                            </button>
                                            <ul
                                                className='ladiui dropdown-menu custom-dropdown-menu'
                                                style={{ minWidth: '100px', textAlign: 'left' }}
                                            >
                                                <li className='ladiui open-modal'>
                                                    <label htmlFor='upload-cart-logo'>Thay ảnh</label>
                                                </li>
                                                <li className='ladiui dropdown-item danger-text' onClick={() => removeCartLogo()}>
                                                    Xóa
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <input
                                    type='file'
                                    id='upload-cart-logo'
                                    name='file'
                                    accept='image/*'
                                    multiple={false}
                                    onChange={fileChangedHandlerCartLogo}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                    style={{ display: 'none' }}
                                />
                            </div>
                            <div className='ls-flex ls-flex-col ls-items-start ls-gap-4'>
                                <div className='ls-text-14 ls-font-medium'>
                                    Logo
                                </div>
                                
                                <div className='ls-text-14 ls-font-normal ls-text-secondary' style={{ whiteSpace: 'nowrap' }}>
                                    Kích thước tối đa 106x32 (px)
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>

                <div className='ls-divide-1'></div>

                <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Favicon
                        </div>
                        <div className='ls-setting-row-description'>
                            Kích thước khuyến nghị 32x32 px
                        </div>
                    </div>
                    <div className='ls-setting-row-right'>
                        <div className='ls-image-setting-row'>
                            <div className='' style={{ width: 'auto', height: 'auto' }}>
                                <div className='block-content-item ls-p-0 ls-shadow-none'>
                                    <div className='block-list-images ls-p-0 ls-shadow-none'>
                                        <UploadImageCustom
                                            handleChangeImageUrl={fileChangedHandlerCartFavicon}
                                            listImage={urlFavicon != '' ? [urlFavicon] : []}
                                            handleDeleteImage={handleDeleteFavicon}
                                            handleSetImage={(items) => { }}
                                            isEditProduct={true}
                                            productID={null}
                                            handleSetListImageProducts={handleSetListImageFavicon}
                                            imageWidth={60}
                                            imageHeight={60}
                                            isAlwayShowButtonUpload={true}
                                            uploadId='upload-favicon'
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className='ls-preview-store-logo'>
                                {
                                    urlFavicon ? <img
                                        className='ls-img-chooser'
                                        src={urlFavicon ? `${CDN}${urlFavicon}` : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'}
                                        alt=''
                                    /> : <i className='new-ldicon-photo ls-size-16'></i>
                                }
                                
                                <div className='ls-img-chooser-overlay'>
                                    <div className='ladiui btn-group'>
                                        <div className='ladiui dropdown'>
                                            <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                <i className='ladiui icon icon-ldp-pen-edit'></i>
                                            </button>
                                            <ul
                                                className='ladiui dropdown-menu custom-dropdown-menu'
                                                style={{ minWidth: '100px', textAlign: 'left' }}
                                            >
                                                <li className='ladiui open-modal'>
                                                    <label htmlFor='upload-cart-favicon'>Thay ảnh</label>
                                                </li>
                                                <li className='ladiui dropdown-item danger-text' onClick={() => removeCartFavicon()}>
                                                    Xóa
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <input
                                    type='file'
                                    id='upload-cart-favicon'
                                    name='file'
                                    accept='image/*'
                                    multiple={false}
                                    onChange={fileChangedHandlerCartFavicon}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                    style={{ display: 'none' }}
                                />
                            </div>
                            <div className='ls-flex ls-flex-col ls-items-start ls-gap-4'>
                                <div className='ls-text-14 ls-font-medium'>
                                    Favicon
                                </div>
                                
                                <div className='ls-text-14 ls-font-normal ls-text-secondary' style={{ whiteSpace: 'nowrap' }}>
                                    Kích thước 16x16 (px)
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>

                <div className='ls-divide-1'></div>

                <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Thông tin cơ bản
                        </div>
                        <div className='ls-setting-row-description'>
                            Tên và thông tin xuất hiện trên trang khóa học của bạn
                        </div>
                    </div>
                    <div className='ls-setting-row-right'>

                        <PanelProductSeoSocialSetting ref={productSeoSocialRef} productInfoSeo={props.currentDataSeo || {}} isShowTitle={true} isShowImageSharing={false} />

                    </div>
                </div>

                <div className='ls-divide-1'></div>

                <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Hình ảnh khi chia sẻ
                        </div>
                        <div className='ls-setting-row-description'>
                            Kích thước khuyến nghị 1200x630 px
                            Định dạng .png, .jpg)
                        </div>
                    </div>
                    <div className='ls-setting-row-right'>
                        <ImageSharingSeoSocialSetting ref={productSeoSocialRef} productInfoSeo={props.currentDataSeo || {}} isShowTitle={true} />
                    </div>
                </div>

                <div className='ls-divide-1'></div>

                {/* <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Tên miền
                        </div>
                        <div className='ls-setting-row-description'>
                            Thiết lập tên miền riêng của bạn để trang khóa học trông chuyên nghiệp hơn.
                        </div>
                    </div>
                    <div className='ls-setting-row-right'>
                       
                        <div className='ladiui form-group mt-0'>
                            <label className='ladiui-label'>Truy cập đường dẫn mặc định</label>
                            <div className='ls-flex ls-justify-start ls-items-center ls-gap-12'>
                                <div className='ls-input-read-only'>
                                    {urlDefault}
                                </div>
                                <button className='ls-btn-outline-primary' onClick={() => window.open(urlDefault, '_blank')}>
                                    Truy cập
                                </button>
                            </div>
                        </div>
                  
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Chọn tên miền trang khóa học</label>
                            <div className='ls-flex ls-justify-start ls-items-center ls-gap-12'>
                                <SelectOption
                                    wrapperStyle={{
                                        minWidth: '250px',
                                        width: '100%',
                                        display: 'inline-block',
                                        zIndex: 999,
                                    }}
                                    innerStyle={{ width: '100%', minWidth: '100%' }}
                                    customStyle={{ padding: '0px', width: '100%' }}
                                    wrapperClassName='select-option-checkout-config'
                                    innerClassName='ellipsis'
                                    currentValue={pageCheckout.domain}
                                    headerItems={[
                                        {
                                            key: 'create',
                                            name: t('CHECKOUTS.ADD_DOMAIN_CONFIG'),
                                            icon: 'icon-c-add',
                                            className: 'prevent-hide ls-dropdown-actions',
                                            is_header: true,
                                        },
                                        {
                                            key: 'refresh',
                                            name: t('COMMON.REFRESH_LIST'),
                                            icon: 'icon-sync',
                                            className: 'prevent-hide ls-dropdown-actions',
                                            is_header: true,
                                        },
                                    ]}
                                    headerKey={'key'}
                                    headerValue={'name'}
                                    items={domainReducer.domains || []}
                                    _key={'domain_id'}
                                    _value={'domain'}
                                    onSelectItem={(item) => {
                                        if (item.domain_id) {
                                            handelChangeDomain(item);
                                        } else {
                                            switch (item.key) {
                                                case 'create':
                                                    if (baseHelper.isIframe()) {
                                                        let query_ls = {
                                                            type: 'setting-checkout',
                                                            is_new: true,
                                                        };
                                                        window.open(
                                                            `${config.BUILDER_LDP}${
                                                                appConfig.LADIPAGE_URL.SETTING_DOMAIN
                                                            }&query_ls=${JSON.stringify(query_ls)}`,
                                                            '_blank'
                                                        );
                                                        return;
                                                    }

                                                    window.open('/settings?type=setting-checkout&is_new=true&tab=DOMAIN');
                                                    break;
                                                case 'refresh':
                                                    dispatch(domainActions.list());
                                                    break;
                                            }
                                        }
                                    }}
                                />
                                <button
                                    className='ls-btn-outline-primary'
                                    onClick={() => {
                                        window.open(`https://${pageCheckout.domain}`, '_blank');
                                    }}
                                >
                                    Truy cập
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='ls-divide-1'></div> */}

                <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Tuỳ chỉnh trạng thái
                        </div>
                        <div className='ls-setting-row-description'>
                            Thiết lập trạng thái hiển thị cho trang khoá học phù hợp với mục đích sử dụng.
                        </div>
                    </div>
                    <div className='ls-setting-row-right'>
                        <div className='ladiui form-group mt-0'>
                            <label className='ladiui-label'>Trạng thái công khai</label>
                            <SelectOption
                                wrapperStyle={{
                                    minWidth: '250px',
                                    width: '100%',
                                    display: 'inline-block',
                                }}
                                innerStyle={{ width: '100%', minWidth: '100%' }}
                                customStyle={{ padding: '0px', width: '100%' }}
                                wrapperClassName='select-option-checkout-config'
                                innerClassName='ellipsis'
                                currentKey={pagePublicType}
                                items={
                                    [
                                        {
                                            key: 'PUBLIC',
                                            name: 'Công khai',
                                        },

                                        {
                                            key: 'MEMBER_ONLY',
                                            name: 'Chế độ học viên',
                                        },
                                        {
                                            key: 'PRIVATE',
                                            name: 'Không công khai',
                                        },
                                    ]
                                }
                                _key={'key'}
                                _value={'name'}
                                onSelectItem={(item) => {
                                    setPagePublicType(item.key);
                                }}
                            />
                            {
                                pagePublicType == 'PUBLIC' && (
                                    <p className="mt-16" style={{ lineHeight: '20px' }}>
                                        <span className='ls-font-medium'>Công khai</span>: Hiển thị danh sách khóa học và danh sách bài học
                                    </p>
                                )
                            }
                            {
                                pagePublicType == 'MEMBER_ONLY' && (
                                    <p className="mt-16" style={{ lineHeight: '20px' }}>
                                        <span className='ls-font-medium'>Chế độ học viên</span>: Khi truy cập vào vẫn có thể xem được danh sách và chi tiết khóa học, nhưng khi học bài (Kể cả bài miễn phí) thì cần phải đăng nhập hoặc đăng ký tài khoản
                                    </p>
                                )
                            }
                            {
                                pagePublicType == 'PRIVATE' && (
                                    <p className="mt-16" style={{ lineHeight: '20px' }}>
                                        <span className='ls-font-medium'>Không công khai</span>: Yêu cầu đăng nhập, đăng ký tài khoản để xem danh sách khoá học
                                    </p>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className='ls-divide-1'></div>

                <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Nhãn phân loại học viên
                        </div>
                        <div className='ls-setting-row-description'>
                            Thiết lập riêng nhãn hiển thị phân loại học viên theo nhu cầu của bạn đối với học viên miễn phí và trả phí.
                        </div>
                    </div>
                    <div className='ls-setting-row-right'>
                        <div className='ls-flex ls-items-center ls-gap-16 ls-w-full'>
                            <div className='ladiui form-group mt-0'>
                                <label className='ladiui-label'>{t('Nhãn tài khoản miễn phí')}</label>
                                <Input
                                    ref={(ref) => inputsRef.add(ref)}
                                    placeholder={t('Nhập nhãn tài khoản miễn phí')}
                                    value={freeAccountLabel}
                                    onChange={(event) => {
                                        setFreeAccountLabel(event.target.value);
                                    }}
                                />
                            </div>

                            <div className='ladiui form-group mt-0'>
                                <label className='ladiui-label'>{t('Nhãn tài khoản trả phí')}</label>
                                <Input
                                    ref={(ref) => inputsRef.add(ref)}
                                    placeholder={t('Nhập nhãn tài khoản trả phí')}
                                    value={paidAccountLabel}
                                    onChange={(event) => {
                                        setPaidAccountLabel(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='ls-divide-1'></div>

                <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Nhãn thể hiện khoá học miễn phí
                        </div>
                        <div className='ls-setting-row-description'>
                            Thiết lập riêng nhãn hiển thị trên trang khi khoá học đó là miễn phí.
                        </div>
                    </div>
                    <div className='ls-setting-row-right'>
                        <div className='ls-flex ls-items-center ls-gap-16 ls-w-full'>
                            <div className='ladiui form-group mt-0'>
                                <label className='ladiui-label'>{t('Nhãn cho khoá học miễn phí')}</label>
                                <Input
                                    ref={(ref) => inputsRef.add(ref)}
                                    placeholder={t('Nhập nhãn cho khoá học miễn phí')}
                                    value={freeCourseLabel}
                                    onChange={(event) => {
                                        setFreeCourseLabel(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div className='ls-divide-1'></div>

                <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Affiliates
                        </div>
                        <div className='ls-setting-row-description'>
                            Chức năng này cho phép bạn có thể triển khai chương trình giới thiệu học viên.
                        </div>
                    </div>
                    <div className='ls-setting-row-right'>
                        <div className='ls-flex ls-items-center ls-gap-16 ls-w-full'>
                            <div className='ladiui form-group mt-0'>
                                {
                                    !currentLadiShareStore ? (
                                        <button className='ls-btn-outline-primary' onClick={() => { handleInitAffiliate(); }}>
                                            Khởi tạo chức năng Afffiliates
                                        </button>
                                    ) : (
                                        <>
                                            <div className='flex mt-16'>
                                                <div className='choose-type-item flex mr-24'>
                                                    <input
                                                        id='active-affiliate'
                                                        type='radio'
                                                        name='radio-group-type'
                                                        className='ladiui form-check-input'
                                                        checked={statusAffiliate == true}
                                                        onClick={() => setStatusAffiliate(true)}
                                                    />
                                                    <label htmlFor='active-affiliate cursor-pointer'>Kích hoạt chức năng Affiliates</label>
                                                </div>
                                                <div className='choose-type-item flex mr-24'>
                                                    <input
                                                        id='inactive-affiliate'
                                                        type='radio'
                                                        name='radio-group-type'
                                                        className='ladiui form-check-input'
                                                        checked={statusAffiliate == false}
                                                        onClick={() => {
                                                            setStatusAffiliate(false);
                                                        }}
                                                    />
                                                    <label htmlFor='inactive-affiliate cursor-pointer'>Tắt chức năng Affiliates</label>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
PanelSettingDomainCourse.propTypes = {};

export default PanelSettingDomainCourse;
