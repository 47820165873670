import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { I18nContext, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import produce from 'immer';
import Input from '../../../../components/Input';
import { map, find } from 'lodash';

// import checkoutConfigActions from "../../../../redux/futures/checkout_config/actions";
// import * as checkoutConfigTypes from "../../../../redux/futures/checkout_config/types";

import pageCheckoutActions from '../../../../redux/futures/page_checkout/actions';
import * as pageCheckoutTypes from '../../../../redux/futures/page_checkout/types';

import domainActions from '../../../../redux/futures/domain/actions';
import * as domainTypes from '../../../../redux/futures/domain/types';

import fileActions from '../../../../redux/futures/file/actions';
import * as fileTypes from '../../../../redux/futures/file/types';

import SelectOption from '../../../../components/SelectOption';
import PanelProductSeoSocialSetting from '../../../../components/PanelProductSeoSocialSetting';

import baseHelper from '../../../../helpers/BaseHelper';

import appConfig from '../../../../config/app';
import config from '../../../../config/config';
import ImageSharingSeoSocialSetting from '../../../../components/ImageSharingSeoSocialSetting';
import UploadImageCustom from '../../../../components/UploadImageCustom';

const CDN = 'https://w.ladicdn.com/';

const PanelSettingDomainCourseTab = forwardRef((props, ref) => {
    let { isShow, currentDataDomain, selectedLogo, urlPublished, selectedPath, handlePreviewPageCourse, currentDataSeo, currentPagePublicType, currentLabelAccountConfigs } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [urlDefault, setUrlDefault] = useState('');

    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);
    const domainReducer = useSelector((state) => state.domainReducer);

    const fileReducer = useSelector((state) => state.file);

    const [pageCheckout, setPageCheckout] = useState({
        checkout_config_id: null,
        domain: '',
    });

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {
                checkout_config_id: pageCheckout.checkout_config_id,
                domain: pageCheckout.domain,
            };
        },
    }));

    useEffect(() => {
        if (currentDataDomain) {
            setPageCheckout({
                checkout_config_id: currentDataDomain.checkout_config_id || null,
                domain: currentDataDomain.domain,
            });
        }
    }, [currentDataDomain]);

    useEffect(() => {
        setUrlDefault(`https://${appConfig.DOMAIN_COURSE_DEFAULT}/${selectedPath}`);
    }, [selectedPath]);

    useEffect(() => {
        let payload = {
            search: {
                type: 'embed',
                embed_platform: 'Ladipage',
            },
        };
        dispatch(pageCheckoutActions.list(payload));
        dispatch(
            domainActions.list({
                types: 'PAGE_COURSE',
                is_verified: 1,
            })
        );
    }, []);

    const handelChangeDomain = (domainData) => {
        setPageCheckout({
            ...pageCheckout,
            domain: domainData.domain,
        });
    };

    return (
        <div className='page-setting' style={isShow ? { display: 'block' } : { display: 'none' }}>
            <div className='ls-flex ls-flex-col ls-items-start ls-gap-24'>
                <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Tên miền
                        </div>
                        <div className='ls-setting-row-description'>
                            Thiết lập tên miền riêng của bạn để trang khóa học trông chuyên nghiệp hơn.
                        </div>
                    </div>
                    <div className='ls-setting-row-right'>

                        <div className='ladiui form-group mt-0'>
                            <label className='ladiui-label'>Truy cập đường dẫn mặc định</label>
                            <div className='ls-flex ls-justify-start ls-items-center ls-gap-12'>
                                <div className='ls-input-read-only'>
                                    {urlDefault}
                                </div>
                                <button className='ls-btn-outline-primary' onClick={() => window.open(urlDefault, '_blank')}>
                                    Truy cập
                                </button>
                            </div>
                        </div>

                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Chọn tên miền trang khóa học</label>
                            <div className='ls-flex ls-justify-start ls-items-center ls-gap-12'>
                                <SelectOption
                                    wrapperStyle={{
                                        minWidth: '250px',
                                        width: '100%',
                                        display: 'inline-block',
                                        zIndex: 999,
                                    }}
                                    innerStyle={{ width: '100%', minWidth: '100%' }}
                                    customStyle={{ padding: '0px', width: '100%' }}
                                    wrapperClassName='select-option-checkout-config'
                                    innerClassName='ellipsis'
                                    currentValue={pageCheckout.domain}
                                    headerItems={[
                                        {
                                            key: 'create',
                                            name: t('CHECKOUTS.ADD_DOMAIN_CONFIG'),
                                            icon: 'icon-c-add',
                                            className: 'prevent-hide ls-dropdown-actions',
                                            is_header: true,
                                        },
                                        {
                                            key: 'refresh',
                                            name: t('COMMON.REFRESH_LIST'),
                                            icon: 'icon-sync',
                                            className: 'prevent-hide ls-dropdown-actions',
                                            is_header: true,
                                        },
                                    ]}
                                    headerKey={'key'}
                                    headerValue={'name'}
                                    items={domainReducer.domains || []}
                                    _key={'domain_id'}
                                    _value={'domain'}
                                    onSelectItem={(item) => {
                                        if (item.domain_id) {
                                            handelChangeDomain(item);
                                        } else {
                                            switch (item.key) {
                                                case 'create':
                                                    if (baseHelper.isIframe()) {
                                                        let query_ls = {
                                                            type: 'setting-checkout',
                                                            is_new: true,
                                                        };
                                                        window.open(
                                                            `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.SETTING_DOMAIN
                                                            }&query_ls=${JSON.stringify(query_ls)}`,
                                                            '_blank'
                                                        );
                                                        return;
                                                    }

                                                    window.open('/settings?type=setting-checkout&is_new=true&tab=DOMAIN');
                                                    break;
                                                case 'refresh':
                                                    dispatch(domainActions.list());
                                                    break;
                                            }
                                        }
                                    }}
                                />
                                <button
                                    className='ls-btn-outline-primary'
                                    onClick={() => {
                                        window.open(`https://${pageCheckout.domain}`, '_blank');
                                    }}
                                >
                                    Truy cập
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
});
PanelSettingDomainCourseTab.propTypes = {};

export default PanelSettingDomainCourseTab;
